<template>
  <div>
    <CRow v-if="candidates.length">
      <CCol
        lg="4"
        v-for="(
          { candidate, actions, sub_status, job_applied,status }, index
        ) in candidatesInfo"
        :key="index"
      >
        <CCard
          class="candidate-card"
          :class="{
            'candidate-active': isSelectedCandidate(candidate.candidate_uid),
          }"
        >
          <CCardBody class="position-relative">
            <div
              class="action-icon d-flex"
              v-if="currentUserRole === Role.customerAdmin"
            >
            <div class="fav-action mx-2">
                <span
                  class="f-1_5 action position-relative multi-select-icon-div"
                  v-if="getMatched(index)"
                >
                  <i
                    v-show="getFavourite(index)"
                    class="text-primary fas fa-heart"
                    v-c-tooltip="
                      ` Click to Remove favourite Candidate`
                    "
                    @click="removeFromFavourite(index)"
                  ></i>
                  <i
                    v-show="!getFavourite(index)"
                    class="far fa-heart"
                    v-c-tooltip="` Click to Add favourite Candidate`"
                    @click="addToFavourite(index)"
                  ></i>
                </span>
              </div>
              <CDropdown placement="top-start">
                <template #toggler>
                  <div class="px-2">
                    <i class="fas fa-ellipsis-v"></i>
                  </div>
                </template>
                <a
                  class="dropdown-item cursor-action"
                  v-for="data in getActions(actions)"
                  :key="data.action_id"
                  @click="openPopup({ index, data })"
                >
                  {{ data.label }}
                </a>
              </CDropdown>
            </div>
            <div
              class="content-job-title cursor-action"
              @click="getCandidateDetails(index)"
            >
              <div class="image-wrapper">
                <img
                  :src="getOrganisationLogo(candidate)"
                  class="rounded-circle"
                  alt="DP"
                />
              </div>
              <span class="d-block"
                ><h5
                  class="m-0"
                  :style="`color: ${getGenderColor(candidate.gender)}`"
                >
                  {{ toTitleCase(candidate.first_name) }}
                  {{ toTitleCase(candidate.surname) }}
                </h5></span
              >
              <span class="d-block">
                <span class="text-muted">{{
                  candidate.candidate_type.candidate_type
                }}</span>
              </span>
              
              <span class="d-block">
                  <span class="text-muted">ID:</span>
                  {{ candidate.candidate_uid }}
                </span>
              <span
                class="d-block"
                v-if="candidate.speciality || candidate.qualifications"
                >{{ speciality(candidate) }}
              </span>
              <span class="d-block">{{ getCountry(candidate) }}</span>
            </div>
            <div class="candidate-status">
              <CBadge
                color="success"
                class="font-weight-normal ml-1"
                v-if="job_applied"
              >
                <span>Applied</span></CBadge
              >
              <CBadge color="primary" class="font-weight-normal ml-1" v-else
                ><span>Talent Pool</span></CBadge
              >
              <CBadge
                color="info"
                class="font-weight-normal ml-1 text-white"
                v-if="status && status['display_text']"
                ><span>{{ status["display_text"] }}</span></CBadge
              >
              <CBadge
                color="warning"
                class="font-weight-normal ml-1 text-white"
                v-if="sub_status && sub_status['display_text'] && !getFavourite(index)"
                ><span>{{ sub_status["display_text"] }}</span></CBadge
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <p v-else class="text-center text-gray h5">No Candidates Found</p>
  </div>
</template>
<script>
import moment from "moment";
import { Role, getScope } from "@/helpers/helper";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    candidatesInfo: {
      type: Array,
      default: () => [],
    },
    selectedCandidate: {
      type: Object,
      default: () => null,
    },
    selectedTabIndex: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      isMobile: false,
      currentUserRole: getScope(),
      Role,
    };
  },
  computed: {
    ...mapGetters([
      "getGenderColor",
      "getJobDetailsById",
      "getSubStatusOptions",
    ]),
    candidates() {
      return (
        (this.candidatesInfo &&
          this.candidatesInfo.length &&
          this.candidatesInfo.map(({ candidate }) => candidate)) ||
        []
      );
    },
    isApplied() {
      return this.candidatesInfo.job_applied;
    },
  },
  methods: {
    ...mapActions(["initUpdateCandidateStatus"]),
    getCandidateDetails(index) {
      let candidateInfo = this.candidatesInfo[index];
      this.$emit("getCandidateDetails", candidateInfo?.candidate_uuid);
    },
    getCountry(data) {
      return data.location?.country?.country_name || null;
    },
    getOrganisationType(data) {
      return data.organisation?.organisation_type?.organisation_type || "--";
    },
    getOrganisationLogo(data) {
      return data.profile_picture;
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    speciality(data) {
      let highestQualification = null;
      data.qualifications &&
        data.qualifications.length &&
        data.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
          }
        });
      let speciality = data.speciality?.speciality || null;
      let sub_speciality = data.sub_speciality?.sub_speciality || null;
      highestQualification = highestQualification
        ? highestQualification.qualification.qualification_with_acronym
        : null;
      if (highestQualification) {
        if (sub_speciality) {
          return speciality + "|" + sub_speciality + "|" + highestQualification;
        } else if (speciality) {
          return speciality + "|" + highestQualification;
        } else {
          return highestQualification;
        }
      } else {
        if (sub_speciality) {
          return speciality + "|" + sub_speciality;
        } else if (speciality) {
          return speciality;
        } else {
          return null;
        }
      }
    },
    isSelectedCandidate(candidateID) {
      if (candidateID && this.selectedCandidate)
        return candidateID === this.selectedCandidate.candidate_uid;
      return false;
    },
    subStatus(sub_status_id) {
      return sub_status_id && this.getSubStatusOptions
        ? this.getSubStatusOptions.find(({ code }) => code === sub_status_id)
            ?.label
        : null;
    },
    openPopup({ index, data }) {
      const candidateDetails = this.candidatesInfo[index];
      let payload = { candidateDetails, selectedAction: data };
      this.$emit("actionModalEvent", payload);
    },
    getActions(actions) {
      return (
        (actions &&
          actions.map((val) => {
            return {
              label: val?.name,
              action_id: val?.id,
              directAction: val?.direct_action,
              status: val?.status,
              filter_id: val?.filter,
              tab_id: val?.tab,
              reason_id: val?.reason,
            };
          })) ||
        []
      );
    },
    getMatched(index) {
      const candidateInfo = this.candidatesInfo[index];
      return candidateInfo?.status_id === 42;
    },
     getFavourite(index) {
      const candidateInfo = this.candidatesInfo[index];
      return candidateInfo?.status_id === 42 && candidateInfo?.sub_status_id === 70;
    },
    addToFavourite(index) {
      const candidateDetails = this.candidatesInfo[index];
      const selectedAction = {
        directAction: true,
        status: { update_status: 42, update_sub_status: 70 },
      };
      let payload = { candidateDetails, selectedAction };
      this.$emit("actionModalEvent", payload);
    },
    removeFromFavourite(index) {
      const candidateDetails = this.candidatesInfo[index];
      const selectedAction = {
        directAction: true,
        status: { update_status: 42, update_sub_status: null },
      };
      let payload = { candidateDetails, selectedAction };
      this.$emit("actionModalEvent", payload);
    },
  },
  mounted() {
    let isMobile = window.matchMedia("only screen and (max-width: 760px)")
      .matches;
    this.isMobile = isMobile;
  },
};
</script>
<style lang="scss" scoped>
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 50px;
  }
}
.cursor-action {
  cursor: pointer;
}
.candidate-card:hover {
  background-color: #fdf1f64d;
}
.candidate-card > .card-body {
  padding-bottom: 30px;
}
.candidate-card.candidate-active {
  background-color: #fdf1f6;
  box-shadow: 0 4px 8px 0 rgba(253, 241, 246, 0.15),
    0 6px 20px 0 rgba(253, 241, 246, 0.14);
}
.action-icon {
  position: absolute;
  top: 1.24em;
  right: 1.4em;
  z-index: 1;
  cursor: pointer;
}

.candidate-status {
  position: absolute;
  bottom: 1.24em;
  // left: 1.4em;
  z-index: 1;
}

.card {
  height: auto;
  min-height: 100%;
}

@media (min-width: 992px) {
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    // border: 1px solid #eee;
    max-width: 30.33333%;
    margin: 0.5rem;
  }
}
</style>
