<template>
  <div>
    <CRow v-if="jobs.length">
      <CCol lg="6" v-for="data in jobs" :key="data.job_id">
        <CCard
          class="job-card"
          :class="{ 'job-active': isSelectedJob(data.job_id) }"
        >
          <CCardBody class="pb-0">
            <div
              class="action-icon"
              v-if="currentUserRole === Role.customerAdmin"
            >
              <CDropdown placement="top-start">
                <template #toggler>
                  <div>
                    <i class="fas fa-ellipsis-v"></i>
                  </div>
                </template>
                <CDropdownItem>ShortList1</CDropdownItem>
                <CDropdownItem>ShortList2</CDropdownItem>
                <CDropdownItem>ShortList3</CDropdownItem>
              </CDropdown>
            </div>
            <div class="content-job-title cursor-action">
              <div class="image-wrapper">
                <img :src="getOrganisationLogo(data)" />
              </div>
              <span class="d-block">{{ data.job_title }}</span>
              <span class="d-block">
                <span class="text-muted">ID:</span>
                {{ data.job_id }}
              </span>
              <span class="d-block"></span>
              <span class="d-block text-muted org" v-if="data.organisation.name">{{
                data.organisation.name
              }}</span>
              <span class="text-muted location">
                <span class="icon-location"></span>
                {{ data.location ? data.location.location : "--" }}
              </span>
              <div
                v-if="status.published"
                class="text-center status"
                style="color: green"
              >
                <i class="fas fa-check-circle"></i>
                {{ status }}
              </div>
              <div
                v-else
                class="text-center status"
                style="color: grey"
              >
                <i class="fas fa-times-circle"></i>
                {{ status }}
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <p v-else class="text-center text-gray h5">No Job Matches Found</p>
  </div>
</template>
<script>
import moment from "moment";
import { Role, getScope } from "@/helpers/helper";
export default {
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    selectedJob: {
      type: Object,
      default: () => null,
    },
    status: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isMobile: false,
      currentUserRole: getScope(),
      Role,
    };
  },
  methods: {
    getOrganisationType(data) {
      return data.organisation?.organisation_type?.organisation_type || "--";
    },
    getOrganisationLogo(data) {
      return data.organisation?.logo_url ? `https://${window.location.host}${data.organisation?.logo_url}` : "/img/hospital.png";
    },
    isSelectedJob(jobID) {
      if (jobID && this.selectedJob) return jobID === this.selectedJob.job_id;
      return false;
    },
  },
  mounted() {
    let isMobile = window.matchMedia("only screen and (max-width: 760px)")
      .matches;
    this.isMobile = isMobile;
  },
  filters: {
    expiry(date) {
      if (date != null) {
        let now = moment().utc();
        let exp = moment().utc(date);
        return now.isAfter(exp) ? "Expired" : "Active";
      }
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 50px;
  }
}
.cursor-action {
  cursor: pointer;
}
.job-card {
  span.location {
    display: block;
    margin: 0.5rem 0;
    font-size: 0.7rem;
  } 
  span.org {
    display: block;
    font-size: 0.8rem
  }
}

.status {
  font-size: 0.8rem;
  margin: 0.5rem 0;
}

.job-card:hover {
  background-color: #fdf1f64d;
}
.job-card.job-active {
  background-color: #fdf1f6;
  box-shadow: 0 4px 8px 0 rgba(253, 241, 246, 0.15),
    0 6px 20px 0 rgba(253, 241, 246, 0.14);
}
.action-icon {
  position: absolute;
  top: 1.24em;
  right: 1.4em;
  z-index: 1;
}
.circle {
  border: 1px solid !important;
  border-radius: 50%;
  text-align: center;
  min-width: 35px;
}
.table-card {
  border-style: solid;
  border-color: darkgray;
  border-width: thin;
  min-height: 40px;
  font-weight: 400;
}
</style>