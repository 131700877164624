<template>
  <div>
    <h6 class="text-primary pb-2">Professional Information</h6>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Type</CCol>
      <CCol col="7" class="text-black">{{ type }}</CCol>
    </CRow>
    <div v-if="!isNurseOrAHP">
      <CRow class="pb-2">
        <CCol col="5" class="text-gray">Speciality</CCol>
        <CCol col="7" class="text-black">{{ speciality }}</CCol>
      </CRow>
      <CRow class="pb-2">
        <CCol col="5" class="text-gray">Sub Speciality</CCol>
        <CCol col="7" class="text-black">{{ subSpeciality }}</CCol>
      </CRow>
      <CRow class="pb-2">
        <CCol col="5" class="text-gray">Special Interest</CCol>
        <CCol col="7" class="text-black">{{ specialInterests }}</CCol>
      </CRow>
      <CRow class="pb-2">
        <CCol col="5" class="text-gray">Eligibility</CCol>
        <CCol col="7" class="text-black">{{ pqrLevels }}</CCol>
      </CRow>
      <CRow class="pb-2">
        <CCol col="5" class="text-gray">Highest Medical Qualification</CCol>
        <CCol col="7" class="text-black">
          {{
            candidate.highest_qualification_acronym
              ? candidate.highest_qualification_acronym
              : candidate.highest_qualification_name
          }}
        </CCol>
      </CRow>
      <CRow class="pb-2">
        <CCol col="5" class="text-gray">Year & Country Obtained</CCol>
        <CCol col="7" class="text-black">
          {{ qualificationYear }} & {{ qualificationCountry }}
        </CCol>
      </CRow>
    </div>
    <div v-else>
      <ProfessionalInfoNurseAH :candidate="candidate" />
    </div>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Regional License</CCol>
      <CCol col="7" class="text-black">{{ activeLicense }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">{{
        candidate.available ? "Join Date/Availability" : "Available From"
      }}</CCol>
      <CCol col="7" class="text-black">{{
        candidate.available ? expectedJoinDate : availableFrom
      }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Languages</CCol>
      <CCol col="7" class="text-black">{{ languages }}</CCol>
    </CRow>
    <h6 class="text-primary pb-2">Personal Information</h6>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Title</CCol>
      <CCol col="7" class="text-black">{{ title }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Known As</CCol>
      <CCol col="7" class="text-black">{{ knownAs }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Date of Birth</CCol>
      <CCol col="7" class="text-black">{{ dob }} ({{ age }} years)</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Nationality</CCol>
      <CCol col="7" class="text-black">{{ nationality }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Birthplace</CCol>
      <CCol col="7" class="text-black">{{ placeOfBirth }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Gender</CCol>
      <CCol col="7" class="text-black">{{ gender }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Martial status</CCol>
      <CCol col="7" class="text-black">{{ martialStatus }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Dependents</CCol>
      <CCol col="7" class="text-black">{{ dependents }}</CCol>
    </CRow>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import ProfessionalInfoNurseAH from "@/containers/JobsDetailedView/Information/ProfessionalInfoNurseAH";
export default {
  props: {
    candidate: {
      type: Object,
      default: null,
    },
  },
  components: {
    ProfessionalInfoNurseAH,
  },
  computed: {
    ...mapGetters([
      "getSelectedCandidateDataForJobsList",
      "getCandidateSpecialInterest",
      "getLicense",
      "getCandidateLanguage",
      "getCandidateNonMedicalQuals",
    ]),
    type() {
      return this.candidate?.candidate_type?.candidate_type || "--";
    },
    speciality() {
      return this.candidate?.speciality?.speciality || "--";
    },
    subSpeciality() {
      return this.candidate?.sub_speciality?.sub_speciality || "--";
    },
    expectedJoinDate() {
      return this.candidate.expected_join_date
        ? moment(this.candidate.expected_join_date).format("DD-MMM-YYYY")
        : "--";
    },
    availableFrom() {
      return this.candidate.available_from
        ? moment(this.candidate.available_from).format("DD-MMM-YYYY")
        : "--";
    },
    activeLicense() {
      let activeLicense = this.getLicense
        ? this.getLicense.some((ele) => ele.current_license == true)
        : null;
      return activeLicense !== null ? (activeLicense ? "Yes" : "No") : "--";
    },
    languages() {
      let languages =
        this.getCandidateLanguage.map(({ language }) => {
          return {
            language_name: language !== null ? language.language : "--",
          };
        }) || [];
      if (languages?.length) {
        return languages
          .map((ele) => {
            return ` ${ele.language_name}`;
          })
          .toString();
      }
      return "--";
    },
    specialInterests() {
      let special_interest =
        this.getCandidateSpecialInterest.map(
          ({ special_interest, other_special_interest }) => {
            return {
              special_interest_name:
                special_interest !== null
                  ? special_interest.special_interest
                  : "Other",
              other_special_interest:
                other_special_interest !== null ? other_special_interest : "--",
            };
          }
        ) || [];
      if (special_interest?.length) {
        return special_interest
          .map((ele) => {
            if (ele.other_special_interest != "--") {
              return ` ${ele.special_interest_name}-${ele.other_special_interest}`;
            }
            return ` ${ele.special_interest_name}`;
          })
          .toString();
      }
      return "--";
    },
    knownAs() {
      return this.candidate?.known_as || "--";
    },
    title() {
      return this.candidate?.title?.title || "--";
    },
    gender() {
      return this.candidate?.gender?.gender || "--";
    },
    martialStatus() {
      return this.candidate?.marital_status || "--";
    },
    dob() {
      return this.candidate?.dob
        ? moment(this.candidate?.dob).format("DD-MM-YYYY")
        : "--";
    },
    age() {
      return this.candidate?.age || "--";
    },
    nationality() {
      return this.candidate?.nationality_names?.join(", ") || "--";
    },
    placeOfBirth() {
      return this.candidate?.birth_place?.country_name || "--";
    },
    dependents() {
      return this.candidate?.dependents || "--";
    },
    pqrLevels() {
      if (
        Array.isArray(this.candidate.pqr_levels) &&
        this.candidate?.pqr_levels?.length
      ) {
        const pqr_levels = this.candidate?.pqr_levels.filter(
          ({ potential }) => !potential
        );
        return pqr_levels.length
          ? pqr_levels[0].pqr_detail?.level?.candidate_level ||
              pqr_levels[0].pqr_ahp_detail?.description ||
              "--"
          : "--";
      }
      return this.candidate.level_name || "--";
    },
    highestQualification() {
      let highestQualification = null;
      this.candidate.qualifications?.length &&
        this.candidate.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
            return true;
          }
          return false;
        });
      return highestQualification;
    },
    qualificationCountry() {
      return this.highestQualification?.country_name || null;
    },
    qualificationYear() {
      return this.highestQualification?.year_awarded;
    },
    isNurseOrAHP() {
      return this.candidate?.candidate_type_id === 2 ||
        this.candidate?.candidate_type_id === 3
        ? true
        : false;
    },
  },
};
</script>
