<template>
  <div class="commom-comment-modal">
    <CModal
      class="text-black"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">Comments History</h6>
        <CButtonClose @click="modalCallBack" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack"
          >Close</CButton
        >
      </template>
      <ValidationObserver ref="commentsform" v-slot="{ handleSubmit }">
        <form
          name="commentsform"
          @submit.prevent="handleSubmit(PostJobComment)"
        >
          <div class="p-2">
            <CRow class="row mb-3">
              <label class="col-lg-12 col-md-12 required">Comment</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <TextareaInput
                    name="comment"
                    :value="comment.comment"
                    @change="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <div class="col-lg-12 col-md-12 col-sm-12 pr-3">
                <CButton
                  type="button"
                  color="primary"
                  :style="'float:right'"
                  class="pr-3"
                  @click="PostJobComment()"
                  >{{ btnText }}</CButton
                >
              </div>
            </CRow>
          </div>
        </form>
      </ValidationObserver>
      <JobComments :jobID="jobID" :job="job" />
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import JobComments from "@/containers/JobsDetailedView/JobComments";
import { mapGetters, mapActions } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    jobID: {
      type: Number,
      default: null,
    },
    job: {
      type: Object,
      default: null,
    },
  },
  components: {
    TextareaInput,
    JobComments,
  },
  data() {
    return {
      comment: {
        comment: "",
      },
      btnText: "Comment",
    };
  },
  mounted() {
    const { job_uuid } = this.job;
    this.fetchJobCommonComments(job_uuid);
  },
  methods: {
    ...mapActions(["fetchJobCommonComments", "postJobComments", "showToast"]),
    handleInput(name, value) {
      Vue.set(this.comment, name, value);
    },
    modalCallBack() {
      this.$emit("commentModalCallBack", "comment");
    },
    async PostJobComment() {
      const isValid = await this.$refs.commentsform.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.btnText = "Adding...";
      const { job_uuid } = this.job;
      let payload = {
        job_id: this.jobID,
        job_uuid,
        comments: this.comment.comment,
      };
      let appendAction = this.postJobComments(payload);
      Promise.all([appendAction]).then((res) => {
        if (res) {
          this.btnText = "Comment";
          this.$refs.commentsform.reset();
          this.comment.comment = null;
          this.fetchJobCommonComments(job_uuid);
        }
      });
    },
  },
};
</script>