<template>
  <div>
    <a class="d-flex btn pl-0"
      @click="
        ($parent.isExpand.internal = !$parent.isExpand.internal),
          $parent.checkExpandAll()
      "
    >
      <span class="pr-1">
        <i
          class="text-primary fas"
          :class="$parent.isExpand.internal ? 'fa-angle-down' : 'fa-angle-right'"
          alt="angle-down"
        />
      </span>
      <h5 class="text-primary">Internal: Job Documentation</h5>
    </a>
    <div v-if="$parent.isExpand.internal">
      <CRow class="mb-3 px-2 d-flex justify-content-center">
        Documents & Contact Details
      </CRow>
      
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
  },
 
  data() {
    return {
      isExpand: false,
    };
  },
};
</script>