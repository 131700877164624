<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.advertising = !$parent.isExpand.advertising),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas"
            :class="
              $parent.isExpand.advertising ? 'fa-angle-down' : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary">Advertising</h5>
      </a>
      <div
        v-if="$parent.isExpand.advertising"
        class="d-flex justify-content-end action-wrapper"
      >
        <a
          class="px-2 btn"
          v-if="currentUserRole === Role.customerAdmin"
          @click="editAdvertisingInfo"
        >
          <i class="fas fa-edit" alt="Edit" />
        </a>
      </div>
    </div>
    <div v-if="$parent.isExpand.advertising" class="gray-box">
      <CRow class="pb-2">
        <CCol col="6" class="text-gray">Advertising Location</CCol>
        <CCol col="6" class="text-black">{{ advertisingLocation }}</CCol>
      </CRow>
    </div>
    <EditAdvertisingInfo
      :jobInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="advertisingModalCallBack"
      v-if="EditModal.isShowPopup"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import EditAdvertisingInfo from "./EditJobDetailSection/EditAdvertisingInfo";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    EditAdvertisingInfo,
  },
  data() {
    return {
      isExpand: false,
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getAdLocationForSelectedID"]),
    advertisingLocation() {
      let data = this.getAdLocationForSelectedID(
        this.profile?.advertisement_location
      ).map((val) => val.label);
      return data?.length ? data.join(", ") : null || "--";
    },
  },
  methods: {
    ...mapActions(["updateJobByDetailPage", "getJobById"]),
    editAdvertisingInfo() {
      this.EditModal.isShowPopup = true;
    },
    advertisingModalCallBack(action, data) {
      if (action && data != null) {
        let { organisation_id, ...rest } = data;
        let { job_id, job_uuid, customer_uid } = this.profile;
        this.updateJobByDetailPage({
          organisation_id,
          customer_uid,
          ...rest,
        }).then((res) => {
          this.getJobById(job_uuid);
          this.EditModal.isShowPopup = false;
        });
      }
      this.EditModal.isShowPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.gray-box {
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 9px;
}
</style>
