<template>
  <div>
    <CRow class="mx-0" v-for="data in jobs" :key="data.job_id">
      <CCol md="12">
        <CCard class="job-card">
          <CCardBody>
            <CRow>
              <CCol md="3" class="content-job-title cursor-action">
                <div class="image-wrapper">
                  <img src="/img/hospital.png" />
                </div>
                <span class="d-block">{{data.job_title}}</span>
                <span class="d-block">
                  <span class="text-muted">ID:</span>
                  {{data.job_id}}
                </span>
              </CCol>
              <CCol md="3" class="text-center" :class="isMobile?'border-top':'border-left'">
                <span class="d-block">{{data.organisation.name || '--'}}</span>
                <span class="d-block">{{getCountry(data)}}</span>
                <span class="d-block">{{getOrganisationType(data)}}</span>
              </CCol>
              <CCol md="3" class="text-center" :class="isMobile?'border-top':'border-left'">
                <span class="d-block">{{data.candidate_type.candidate_type || '--'}}</span>
                <span class="d-block">{{level(data)}}</span>
              </CCol>
              <CCol md="3" class="text-center" :class="isMobile?'border-top':'border-left'">
                <span class="d-block">{{data.expiry_review_date|expiry}}</span>
                <span class="d-block" v-if="data.deleted">Deleted</span>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <p v-if="jobs.length === 0" class="text-center text-gray h5">No Job Matches Found</p>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    getCountry(data) {
      return data.location?.country?.country_name || "--";
    },
    getOrganisationType(data) {
      return data.organisation?.organisation_type?.organisation_type || "--";
    },
    level(data){
      return data?.level?.candidate_level || "--";
    },
  },
  mounted() {
    let isMobile = window.matchMedia("only screen and (max-width: 760px)")
      .matches;
    this.isMobile = isMobile;
  },
  filters: {
    expiry(date) {
      if (date != null) {
        let now = moment().utc();
        let exp = moment().utc(date);
        return now.isAfter(exp) ? "Expired" : "Active";
      }
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 50px;
  }
}
.cursor-action {
  cursor: pointer;
}
// .job-card:hover {
//   background-color: #e4f8fa;
// }
.action-icon {
  position: absolute;
  top: 1.24em;
  right: 1.4em;
  z-index: 1;
}
</style>