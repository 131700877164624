<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">Additional Information</h6>
      <div class="d-flex justify-content-end action-wrapper">
        <a
          class="px-2 btn"
          v-if="currentUserRole == Role.customerAdmin"
          @click="editAdditionalInfo"
        >
          <em class="fas fa-pencil-alt" alt="Edit" title="Click here to edit" />
        </a>
      </div>
    </div>
    <CRow class="pb-2">
      <CCol lg="12">
        <span class="text-black">{{ profile.additional_info || "--" }}</span>
      </CCol>
    </CRow>
    <!-- <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">Comments</h6>
      <div class="d-flex justify-content-end action-wrapper">
        <a
          class="px-2 btn"
          v-if="currentUserRole == Role.customerAdmin"
          @click="editCommentsInfo"
        >
          <em class="fas fa-edit" alt="Edit" />
        </a>
      </div>
    </div>
    <CRow class="pb-2">
      <CCol lg="12">
        <span class="text-black">{{ profile.comments || "--" }}</span>
      </CCol>
    </CRow> -->
    <CModal
      color="primary"
      :show.sync="editModal.isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          {{ editAddInfo ? "Additional Info Edit" : "Comments Info Edit" }}
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="modalCallBack(true)"
          >Save</CButton
        >
      </template>
      <div>
        <ValidationObserver
          ref="editConsultantDetails"
          v-slot="{ handleSubmit }"
        >
          <form
            @submit.prevent="handleSubmit(modalCallBack)"
            name="editConsultantDetails"
          >
            <CRow v-if="editAddInfo"
              ><label class="col-lg-12 col-md-12">Additional Information</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <TextareaInput
                  name="additional_info"
                  :value="jobs.additional_info"
                  @input="handleInput"
                /></div
            ></CRow>
            <CRow v-if="editCmtInfo">
              <label class="col-lg-12 col-md-12">Comments</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <TextareaInput
                  name="comments"
                  :value="jobs.comments"
                  @input="handleInput"
                /></div
            ></CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import { Role, getScope, deepClone } from "@/helpers/helper";
import { mapGetters, mapActions } from "vuex";
import AddJob from "../AddJobs/AddJob";

export default {
  mixins: [AddJob],
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      editModal: {
        isShowPopup: false,
      },
      editAddInfo: false,
      editCmtInfo: false,
      jobs: {},
      payload: {},
    };
  },
  methods: {
    ...mapActions(["updateJobByDetailPage", "getJobById"]),
    editAdditionalInfo() {
      this.jobs = deepClone(this.profile);
      this.editModal.isShowPopup = true;
      this.editAddInfo = true;
    },
    editCommentsInfo() {
      this.jobs = deepClone(this.profile);
      this.editModal.isShowPopup = true;
      this.editCmtInfo = true;
    },
    modalCallBack(action) {
      if (action && Object.keys(this.payload).length) {
        const {
          customer_uid,
          job_id,
          job_uuid,
          organisation: { organisation_id },
        } = this.profile;
        let finalPayload = {};
        if (this.editAddInfo) {
          finalPayload = {
            additional_info: this.payload.additional_info,
            job_id: job_id,
          };
        } else if (this.editCmtInfo) {
          finalPayload = { comments: this.payload.comments, job_id: job_id };
        }
        this.updateJobByDetailPage({
          organisation_id,
          customer_uid,
          ...finalPayload,
        }).then((res) => {
          this.getJobById(job_uuid);
        });
      }
      this.resetData();
      this.editModal.isShowPopup = false;
    },
    resetData() {
      this.editAddInfo = false;
      this.editCmtInfo = false;
      this.payload = {};
    },
  },
};
</script>
<style scoped>
.text-gray {
  color: darkgrey !important;
}
</style>
