<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Candidate</th>
          <th>Submitted by</th>
          <th>Stage</th>
          <th>Updated</th>
          <th>Comments</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, index) in getCandidateListForJobsList"
          :key="index"
          v-bind:class="{ selected: item === index }"
        >
          <td
            class="d-flex flex-row pointer"
            @click="getSelectedCandidate(data, index)"
          >
            <i class="fa fa-user-circle fa-3x" aria-hidden="true"></i>
            <span class="d-flex flex-column pl-1 pointer"
              ><span class="fs-13" style="color:#1571cc; font-weight:500">{{
                data.full_name
              }}</span
              ><span> {{ data.candidateRole }}</span></span
            >
          </td>
          <td class="align-middle">{{ data.submittedBy }}</td>
          <td class="align-middle">{{ data.stage }}</td>
          <td class="align-middle">{{ data.updated }}</td>
          <td class="align-middle">{{ data.comments }}</td>
          <td class="align-middle">
            <i class="fas fa-edit pointer action-button" />
            <i class="fas fa-trash ml-3 pointer action-button" />
            <i class="fas fa-ellipsis-v ml-3 pointer action-button" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";

export default {
  data() {
    return {
      item: 0,
    };
  },
  methods: {
    ...mapActions(["fetchCandidateList", "setSelectedCandidateUsingId"]),
    getSelectedCandidate(data, index) {
      this.item = index;
      this.setSelectedCandidateUsingId(data.candidate_uid);
    },
  },
  computed: {
    ...mapGetters(["getCandidateListForJobsList"]),
  },
  created() {
    if(!isObject(this.getCandidateListForJobsList) && isEmptyObjectCheck(this.getCandidateListForJobsList))
      this.fetchCandidateList();
  },
};
</script>

<style lang="scss" scoped>
.fs-13 {
  font-size: 13px;
}
.pointer {
  cursor: pointer !important;
}
.selected {
  background-color: #fdf1f6;
}
</style>
