<template>
  <div class="auditTrail-History">
    <LoadingOverlay v-if="isFetchingAuditHistory" />
    <CRow class="d-flex justify-content-start">
      <CCol md="4" class="pr-0 mb-3">
        <label class="mb-1">History:</label>
        <Select
          name="filter"
          :value="filter.filter"
          @input="handleChangeSelect"
          :options="options && options['filter'] ? options['filter'] : []"
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.filter && filter.filter.code == 'communications'"
      >
        <label class="mb-1">Communication Type:</label>
        <Select
          name="sub_filter"
          :value="filter.sub_filter"
          @input="handleChangeSelect"
          :options="
            options && options['communication_type']
              ? options['communication_type']
              : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol
        md="4"
        class="pr-0 mb-3"
        v-if="filter.filter && filter.filter.code == 'comments'"
      >
        <label class="mb-1">Comments Type:</label>
        <Select
          name="sub_filter"
          :value="filter.sub_filter"
          @input="handleChangeSelect"
          :options="
            options && options['comments_type'] ? options['comments_type'] : []
          "
          :taggable="false"
          :clearable="false"
        />
      </CCol>
      <CCol md="4" class="pr-0 mb-3">
        <label class="mb-1">Date Range:</label>
        <DatePicker
          name="dateRange"
          :value="filter.dateRange"
          @input="handleDatePickerChange"
          :showTillToday="true"
          :range="true"
          returnType="format"
        />
      </CCol>
    </CRow>
    <CRow class="d-flex justify-content-start">
      <CCol md="4" class="pr-0 mb-3">
        <label class="mb-1 show-search-label">Search:</label>
        <text-input
          name="searchTerm"
          :value="filter.searchTerm"
          @input="handleInput"
          placeholder="Search Candidates..."
        />
      </CCol>
      <CCol md="8" class="pr-0 mb-3">
        <CButton
          class="btn-primary small mr-3"
          @click="searchTerm(true)"
          :disabled="disableSearch"
          >Search</CButton
        >
        <div class="mt-2 filter-details d-inline-block" v-if="search">
          <div class="filter-details-item d-flex vs__selected-options">
            <span>Search Term :</span>
            <div class="m-0 ml-1 vs__selected">
              <span>
                {{ search }}
              </span>
              <button
                type="button"
                aria-label="Deselect option"
                class="vs__deselect"
                @click="searchTerm(false)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                  <path
                    d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
    <div class="container timeline-scrolling" id="history-info">
      <div class="row">
        <div class="col-md-11">
          <ul v-if="filter.filter.code == 'statusChange'" class="timeline pt-3">
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <time class="time m-0">
                <span>{{ formatTime(data.created_on) }}</span>
                <span>{{ formatDate(data.created_on) }}</span>
              </time>

              <div class="icon" :style="getTheme('statusChange').bg_color">
                <em
                  :class="getTheme('statusChange').src"
                  style="color: white"
                ></em>
              </div>
              <div class="label">
                <p class="h6" v-if="data.comments">
                  <span v-if="!data._toggled"
                    >{{ data.comments.substring(0, 60)
                    }}{{ data.comments.substring(61) ? "..." : "" }}</span
                  ><span v-else style="line-height: normal">{{
                    data.comments
                  }}</span
                  ><span
                    ><CButton
                      v-if="data.comments.substring(61)"
                      @click="moreToggle(data, index)"
                      class="btn btn-link p-0"
                    >
                      {{ data._toggled ? "Show less" : "Show more" }}
                    </CButton>
                  </span>
                </p>
                <p class="pl-3 mb-2">
                  <a
                    class="h6"
                    href="javascript:void(0)"
                    @click="navigateToCandidateDetail(data.candidate_uuid)"
                    >{{ formatName(data.candidate_name) }}</a
                  >
                  moved
                  {{ data.previous_status ? `from ` : "" }}
                  <strong
                    >{{ data.previous_status
                    }}{{
                      data.previous_sub_status
                        ? `(${data.previous_sub_status}) `
                        : " "
                    }}</strong
                  >
                  to
                  <strong
                    >{{ data.status
                    }}{{
                      data.sub_status ? `(${data.sub_status})` : ""
                    }}</strong
                  >
                  by
                  <strong>{{ formatName(data.created_user) }}</strong>
                </p>
              </div>
            </li>
          </ul>

          <ul v-if="filter.filter.code == 'comments'" class="timeline pt-3">
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <time class="time m-0">
                <span>{{ formatTime(data.created_on) }}</span>
                <span>{{ formatDate(data.created_on) }}</span>
              </time>

              <div class="icon" :style="getTheme('comments').bg_color">
                <em :class="getTheme('comments').src" style="color: white"></em>
              </div>
              <div class="label">
                <p class="h6" v-if="data.comments">
                  <span v-if="!data._toggled"
                    >{{ data.comments.substring(0, 60)
                    }}{{ data.comments.substring(61) ? "..." : "" }}</span
                  ><span v-else style="line-height: normal">{{
                    data.comments
                  }}</span
                  ><span
                    ><CButton
                      v-if="data.comments.substring(61)"
                      @click="moreToggle(data, index)"
                      class="btn btn-link p-0"
                    >
                      {{ data._toggled ? "Show less" : "Show more" }}
                    </CButton>
                  </span>
                </p>
                <p class="pl-3 mb-2">
                  Updated by
                  <strong>{{ formatName(data.created_user) }}</strong>
                  <span v-if="data.candidate_uid">
                    for
                    <a
                      class="h6"
                      href="javascript:void(0)"
                      @click="navigateToCandidateDetail(data.candidate_uuid)"
                      >{{ formatName(data.candidate_name) }}</a
                    ></span
                  >.
                </p>
              </div>
            </li>
          </ul>

          <ul
            v-if="filter.filter.code == 'communications'"
            class="timeline pt-3"
          >
            <li v-for="(data, index) in getAuditHistory" :key="index">
              <time class="time m-0">
                <span>{{ formatTime(data.created_on) }}</span>
                <span>{{ formatDate(data.created_on) }}</span>
              </time>

              <div class="icon" :style="getTheme(data.contact_method).bg_color">
                <em
                  :class="getTheme(data.contact_method).src"
                  style="color: white"
                ></em>
              </div>
              <div class="label">
                <p class="h6" v-if="data.comments">
                  <span v-if="!data._toggled"
                    >{{ data.comments.substring(0, 60)
                    }}{{ data.comments.substring(61) ? "..." : "" }}</span
                  ><span v-else style="line-height: normal">{{
                    data.comments
                  }}</span
                  ><span
                    ><CButton
                      v-if="data.comments.substring(61)"
                      @click="moreToggle(data, index)"
                      class="btn btn-link p-0"
                    >
                      {{ data._toggled ? "Show less" : "Show more" }}
                    </CButton>
                  </span>
                </p>
                <p
                  class="pl-3 mb-2"
                  v-if="filter.sub_filter && filter.sub_filter.code == 'email'"
                >
                  <strong>{{ formatName(data.created_user) }}</strong>
                  has sent a mail with subject named "<strong
                    >{{ data.email_conversation.subject }} </strong
                  >" to
                  <a
                    class="h6"
                    href="javascript:void(0)"
                    @click="navigateToCandidateDetail(data.candidate_uuid)"
                    >{{ formatName(data.candidate_name) }} </a
                  >.
                  <CButton class="btn btn-link p-0" @click="showMsgModal(data)">
                    <em class="fas fa-eye"></em> View Message
                  </CButton>
                </p>
              </div>
            </li>
          </ul>

          <h5
            class="text-center"
            v-if="!(getAuditHistory && getAuditHistory.length)"
          >
            No Data Found!
          </h5>
        </div>
      </div>
    </div>
    <CModal color="primary" :show.sync="isShowMsgModal">
      <template #header>
        <h5>Detailed Message</h5>
        <button
          aria-label="Close"
          class="close"
          @click="isShowMsgModal = false"
        >
          x
        </button>
      </template>
      <template #footer>
        <CButton color="primary" @click="isShowMsgModal = false">Close</CButton>
      </template>
      <p class="h6">
        <strong>{{ selectedMessage.subject }}</strong>
      </p>
      <p class="pl-3" v-html="selectedMessage.body"></p>
      <span
        class="attachment-item"
        v-for="(attachment, index) in selectedMessage.email_attachment"
        :key="index"
      >
        <a v-if="attachment" @click="downloadFile(attachment)"
          ><i class="fa fa-paperclip" aria-hidden="true"></i
          >{{ attachment.file_name }}</a
        >
      </span>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import m from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import TextInput from "@/components/reusable/Fields/TextInput";

export default {
  name: "HistoryInfo",
  props: ["jobDetails"],
  components: {
    Select,
    DatePicker,
    TextInput,
  },
  data: () => ({
    filter: {
      filter: { label: "Status Changes", code: "statusChange" },
      sub_filter: null,
    },
    search: "",
    isShowMsgModal: false,
    selectedMessage: {},
  }),
  computed: {
    ...mapGetters([
      "isFetchingAuditHistory",
      "getAuditHistory",
      "getAuditHistoryPagination",
      "getContactMethods",
    ]),
    options() {
      return {
        filter: [
          { label: "Status Changes", code: "statusChange" },
          { label: "Comments", code: "comments" },
          { label: "Communications", code: "communications" },
        ],
        communication_type: this.getContactMethods || [],
        comments_type: [
          { label: "Private Comments", code: "privateJobComments" },
          { label: "Job Related Comments", code: "comments" },
        ],
      };
    },
    disableSearch() {
      return this.filter.filter.code == "statusChange" ||
        (this.filter.sub_filter &&
          !(this.filter.sub_filter?.code == "privateJobComments"))
        ? false
        : true;
    },
  },
  beforeDestroy() {
    const ele = document.getElementById("history-info");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  watch: {
    "$route.params.id": function (id) {
      this.filter.filter = { label: "Status Changes", code: "statusChange" };
      this.search = "";
      this.filter.searchTerm = "";
      this.fetchJobAuditHistory(false);
    },
  },
  methods: {
    ...mapActions([
      "fetchAuditHistory",
      "setCandidateListBackUrl",
      "downloadAttachment",
    ]),
    ...mapMutations(["RESET_AUDIT_HISTORY"]),
    formatTime(data) {
      return data ? m.utc(data).local().format("hh:mm A") : "--";
    },
    formatDate(data) {
      return data ? m.utc(data).local().format("DD-MMM-YYYY") : "--";
    },
    formatName(str) {
      return str?.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    handleInput(name, value) {
      Vue.set(this.filter, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.filter, name, value);
      if (name == "filter") {
        this.filter.searchTerm = "";
        this.search = "";
        if (value?.code == "communications" || value?.code == "comments") {
          this.filter.sub_filter = null;
          this.$store.commit("RESET_AUDIT_HISTORY");
          return;
        }
      }
      if (name == "sub_filter") {
        if (value?.code == "privateJobComments") {
          this.filter.searchTerm = "";
          this.search = "";
        }
      }
      this.fetchJobAuditHistory(false);
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.filter, name, value);
      this.fetchJobAuditHistory(false);
    },
    moreToggle(data, index) {
      this.$set(this.getAuditHistory[index], "_toggled", !data._toggled);
    },
    searchTerm(search) {
      if (search && this.filter?.searchTerm) {
        this.search = this.filter?.searchTerm;
        this.fetchJobAuditHistory(false);
      } else {
        if (this.search) {
          this.search = "";
          this.filter.searchTerm = "";
          this.fetchJobAuditHistory(false);
        }
        this.search = "";
      }
    },
    fetchJobAuditHistory(pagination) {
      let payload = {
        job_id: this.jobDetails?.job_id,
        history: this.filter?.filter?.code,
        pagination: pagination,
        from_date: this.filter?.dateRange[0],
        to_date: this.filter?.dateRange[1],
      };
      if (this.search)
        payload = {
          ...payload,
          searchTerm: encodeURIComponent(this.search),
        };
      if (this.filter?.filter?.code == "communications") {
        payload = {
          ...payload,
          contact_method: this.filter?.sub_filter?.code,
        };
      }
      if (this.filter?.filter?.code == "comments") {
        payload.history = this.filter?.sub_filter?.code;
      }
      this.fetchAuditHistory(payload);
    },
    onBodyScroll(e) {
      if (
        this.isFetchingAuditHistory ||
        this.getAuditHistoryPagination?.noMoreData
      )
        return;
      if (
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 1
      ) {
        this.fetchJobAuditHistory(true);
      }
    },
    navigateToCandidateDetail(candidate_uuid) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.$router.push(`/candidate/${candidate_uuid}`);
    },
    showMsgModal(data) {
      this.selectedMessage = data.email_conversation;
      this.isShowMsgModal = true;
    },
    downloadFile(attachment) {
      let attachment_id = attachment.attachment_id;
      let appendAction = this.downloadAttachment({ attachment_id });
      Promise.all([appendAction]).then((res) => {
        let url = res[0];
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", attachment.file_name);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getTheme(data) {
      let src = "";
      let color = "";
      switch (data) {
        case "statusChange":
          src = "fas fa-user-md";
          color = "#DC0050 !important";
          break;
        case "comments":
          src = "fas fa-comment";
          color = "#1973e8;";
          break;
        case "email":
          src = "fas fa-envelope";
          color = "#50d38a";
          break;
        case "whatsapp":
          src = "fas fa-whatsapp";
          color = "#25D366;";
          break;
        case "phone":
          src = "fas fa-phone";
          color = "#B23850;";
          break;
        case "message":
          src = "fas fa-sms";
          color = "#61892F;";
          break;
        case "mail":
          src = "fas fa-mail-bulk";
          color = "#A64AC9;";
          break;
        default:
          src = "fas fa-history";
          color = "#A64AC9;";
      }
      return {
        src: src,
        bg_color: "background-color: " + color,
      };
    },
  },
  mounted() {
    this.filter.dateRange = [
      m().subtract(30, "days").format("YYYY-MM-DD"),
      m().format("YYYY-MM-DD"),
    ];
    this.fetchJobAuditHistory(false);
    const ele = document.getElementById("history-info");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>

<style lang="scss" scoped>
.show-search-label {
  display: none;
}
@media (max-width: 767px) {
  .show-search-label {
    display: block;
  }
}
</style>
