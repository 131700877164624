var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CModal',{attrs:{"color":"primary","show":_vm.isShowPopup,"close-on-backdrop":false,"size":"lg"},on:{"update:show":function($event){_vm.isShowPopup=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v(" Qualifications & Preferences Plus Additional Information Edit ")]),_c('CButtonClose',{staticClass:"text-black",on:{"click":function($event){return _vm.modalCallBack(false)}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"px-4",attrs:{"type":"button","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.modalCallBack(false)}}},[_vm._v("Close")]),_c('CButton',{staticClass:"px-4",attrs:{"type":"button","color":"primary"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("Save")])]},proxy:true}])},[(!_vm.isFetching)?_c('LoadingOverlay'):_c('div',[_c('ValidationObserver',{ref:"EditQualAndPrefInfo",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"name":"EditQualAndPrefInfo"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',{staticClass:"mt-3"},[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Full Time")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('RadioButton',{attrs:{"name":"full_time","value":_vm.jobs.full_time,"options":_vm.options && _vm.options['workingHours']
                        ? _vm.options['workingHours']
                        : []},on:{"change":_vm.handleChangeRadio}})],1)])],1),_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Part Time")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('RadioButton',{attrs:{"name":"part_time","value":_vm.jobs.part_time,"options":_vm.options && _vm.options['workingHours']
                        ? _vm.options['workingHours']
                        : []},on:{"change":_vm.handleChangeRadio}})],1)])],1)],1),_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Permanent Work")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('RadioButton',{attrs:{"name":"permanent_work","value":_vm.jobs.permanent_work,"options":_vm.options && _vm.options['boolean'] ? _vm.options['boolean'] : []},on:{"change":_vm.handleChangeRadio}})],1)])],1),_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Temporary Work")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('RadioButton',{attrs:{"name":"temporary_work","value":_vm.jobs.temporary_work,"options":_vm.options && _vm.options['boolean'] ? _vm.options['boolean'] : []},on:{"change":_vm.handleChangeRadio}})],1)])],1)],1),_c('CRow',[(
                _vm.jobs.working_hours && _vm.jobs.working_hours.detail_number != null
              )?_c('CCol',{staticClass:"mb-3 col-md-6"},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Working Hours (Per Week)")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":{ max_value_hours: 48 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"working_hours","type":"Number","min":0,"max":48,"value":_vm.jobs.working_hours && _vm.jobs.working_hours.detail_number,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1):_vm._e(),(
                _vm.jobs.working_days && _vm.jobs.working_days.detail_number != null
              )?_c('CCol',{staticClass:"mb-3 col-md-6"},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Working Days (per week)")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":{ max_value_days: 7 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"working_days","type":"Number","min":0,"max":7,"value":_vm.jobs.working_days && _vm.jobs.working_days.detail_number,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1):_vm._e()],1),_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Hospital/Clinic Based")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"organisation_type","value":_vm.jobs.organisation_type,"error":errors[0],"disabled":true},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1)],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }