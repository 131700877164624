<template>
  <div>
    <div class="mt-2 text-right">
      <button
        class="text-primary bg-white"
        style="border:none; outline:none; font-weight: 500;"
        @click="expandAll()"
      >
        {{ isExpandAll ? "- Collapse All" : "+ Expand All" }}
      </button>
    </div>
    <div class="qualification-section mt-2">
      <QualificationInfo :profile="profile" :isMobile="isMobile" />
    </div>
    <!-- <div class="qualification-section mt-2 pt-4">
      <SalaryBenefitsInfo :profile="profile" :isMobile="isMobile" />
    </div>
    <div class="qualification-section mt-2 pt-4">
      <InternalInfo :profile="profile" :isMobile="isMobile" />
    </div>
    <div class="qualification-section mt-2 pt-4">
      <AdvertisingInfo :profile="profile" :isMobile="isMobile" />
    </div> -->
  </div>
</template>
<script>
import moment from "moment";
import { Role, getScope } from "@/helpers/helper";
import { mapGetters, mapActions } from "vuex";
import QualificationInfo from "@/containers/JobsDetailedView/JobDetailSection/QualificationPreferencesInfo";
import SalaryBenefitsInfo from "@/containers/JobsDetailedView/JobDetailSection/SalaryBenefitsInfo";
import InternalInfo from "@/containers/JobsDetailedView/JobDetailSection/InternalInfo";
import AdvertisingInfo from "@/containers/JobsDetailedView/JobDetailSection/AdvertisingInfo";
export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    QualificationInfo,
    SalaryBenefitsInfo,
    InternalInfo,
    AdvertisingInfo,
  },
  data() {
    return {
      isMobile: false,
      isExpandAll: false,
      isExpand: {
        qualification: false,
        salary: false,
        detailedDescription: false,
        internal: false,
        advertising: false,
      },
    };
  },
  mounted() {
    let isMobile = window.matchMedia("only screen and (max-width: 760px)")
      .matches;
    this.isMobile = isMobile;
  },
  methods: {
    expandAll() {
      this.isExpandAll = !this.isExpandAll;
      for (const [key, value] of Object.entries(this.isExpand)) {
        this.isExpand[key] = this.isExpandAll;
      }
    },
    checkExpandAll() {
      let temp = false;
      for (const [key, value] of Object.entries(this.isExpand)) {
        value ? (temp = true) : null;
      }
      temp ? null : (this.isExpandAll = false);
    },
  },
};
</script>
