<template>
  <div class="jobBenefits-info-section">
    <div class="d-flex align-items-center justify-content-between">
      <a
        class="d-flex btn pl-0"
        @click="
          ($parent.isExpand.salary = !$parent.isExpand.salary),
            $parent.checkExpandAll()
        "
      >
        <span class="pr-1">
          <i
            class="text-primary fas"
            :class="
              $parent.isExpand.salary ? 'fa-angle-down' : 'fa-angle-right'
            "
            alt="angle-down"
          />
        </span>
        <h5 class="text-primary">Salary & Benefits</h5>
      </a>
    </div>
    <div v-if="$parent.isExpand.salary" class="gray-box">
      <CRow class="pb-2">
        <CCol lg="6">
          <label class="text-gray">Salary Range</label>
          <span class="text-black"
            >{{ profile.salary_min || "--" }} {{ salary_currency }} to
            {{ profile.salary_max || "--" }} {{ salary_currency }}
          </span>
        </CCol>
        <CCol lg="6">
          <label class="text-gray">Target Salary</label>
          <span class="text-black"
            >{{ profile.target_salary || "--" }} {{ target_currency }}</span
          >
        </CCol>
      </CRow>
      <CRow class="pb-2">
        <CCol>
          <label class="text-gray">Benefits</label>
          <CCol col="7" class="text-black" v-if="!benefits">--</CCol>
          <CCol class="text-black" v-else v-html="benefits"> </CCol>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import AddJob from "../../AddJobs/AddJob";
export default {
  mixins: [AddJob],
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      isExpand: false,
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedJobBenefitsAndAdvertDesc",
      "getJobDetailsById",
      "getCurrencyForSelectedID",
    ]),
    target_currency() {
      return this.getCurrencyForSelectedID(
        this.profile?.target_salary_currency_id
      ).map((val) => val.label)[0];
    },
    salary_currency() {
      return this.getCurrencyForSelectedID(
        this.profile?.salary_currency_id
      ).map((val) => val.label)[0];
    },
    benefits() {
      let stdBenefits = [];
      let addBenefits = [];
      let benefits = this.getSelectedJobBenefitsAndAdvertDesc?.benefits
        ? this.getSelectedJobBenefitsAndAdvertDesc?.benefits?.map((val) => ({
            benefit_type: val?.benefit_types,
            comments: val.comments,
            description: val.description,
            display_order: val?.organisation_job_benefit_display_order,
          }))
        : this.getJobDetailsById?.benefits?.map((val) => ({
            benefit_type: val?.organisation_job_benefit?.benefit_types,
            comments: val.comments,
            description: val.description,
            display_order: val?.organisation_job_benefit?.display_order,
          }));
      benefits?.forEach((item) => {
        item?.benefit_type == "standard_benefits"
          ? stdBenefits.push(item)
          : addBenefits.push(item);
      });
      stdBenefits = stdBenefits.sort(
        (a, b) => a.display_order - b.display_order
      );
      addBenefits = addBenefits.sort(
        (a, b) => a.display_order - b.display_order
      );
      benefits = stdBenefits.concat(addBenefits);
      if (benefits?.length) {
        let list = "";
        benefits.forEach((item) => {
          list += `<li>${item.description}${
            item.comments ? ` -${item.comments}` : ""
          }</li>`;
        });
        return list;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["updateJobByDetailPage", "getJobById"]),
    editSalaryBenefitsInfo() {
      this.EditModal.isShowPopup = true;
    },
    salaryBenefitsModalCallBack(action, data) {
      if (action && data != null) {
        let { organisation_id, ...rest } = data;
        let { job_id, job_uuid, customer_uid } = this.profile;
        this.updateJobByDetailPage({
          organisation_id,
          customer_uid,
          ...rest,
        }).then((res) => {
          this.getJobById(job_uuid);
          this.EditModal.isShowPopup = false;
        });
      }
      this.EditModal.isShowPopup = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.gray-box {
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 9px;
}
</style>
