<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">
        Salary & Benefits
        <CIcon
          name="cil-info"
          class="hinticon-multi-select"
          v-c-tooltip="
            ' See Details Section below for Additional Details'
          "
        />
        <i
          class="fas fa-angle-double-down"
          style="cursor: pointer"
          @click="$parent.goToSalaryAndBenefitsSection()"
        ></i>
      </h6>
    </div>
    <CRow class="pb-2">
      <CCol lg="6">
        <label class="text-gray">Salary Range</label>
        <span class="text-black"
          >{{ profile.salary_min || "--" }} {{ salary_currency }} to
          {{ profile.salary_max || "--" }} {{ salary_currency }}
        </span>
      </CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol lg="6">
        <label class="text-gray">Target Salary</label>
        <span class="text-black"
          >{{ profile.target_salary || "--" }} {{ target_currency }}</span
        >
      </CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol>
        <h6 class="text-primary pb-2">Reviewer(s)</h6>
        <span class="text-black">{{ recruiter_names }}</span>
      </CCol>
      <CCol>
        <h6 class="text-primary pb-2">Interviewer(s)</h6>
        <span class="text-black">{{ interviewer_names }}</span>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import { mapGetters } from "vuex";
export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
    };
  },
  computed: {
    ...mapGetters(["getCurrencyForSelectedID"]),
    target_currency() {
      return this.getCurrencyForSelectedID(
        this.profile?.target_salary_currency_id
      ).map((val) => val.label)[0];
    },
    salary_currency() {
      return this.getCurrencyForSelectedID(
        this.profile?.salary_currency_id
      ).map((val) => val.label)[0];
    },
    recruiter_names() {
      let fullname = [];
      const fname = this.profile?.job_candidate_users
        ?.filter((val) => val.customer_user?.customer_user_type_id == 1)
        ?.map((val) => val.customer_user?.user?.first_name);
      const lname = this.profile?.job_candidate_users
        ?.filter((val) => val.customer_user?.customer_user_type_id == 1)
        ?.map((val) => val.customer_user?.user?.surname);
      for (let i in fname) {
        fullname[i] = fname[i] + " " + lname[i];
      }
      return fullname?.length ? fullname.join(", ") : null || "--";
    },
    interviewer_names() {
      let fullname = [];
      const fname = this.profile?.job_candidate_users
        ?.filter((val) => val.customer_user?.customer_user_type_id == 2)
        ?.map((val) => val.customer_user?.user?.first_name);
      const lname = this.profile?.job_candidate_users
        ?.filter((val) => val.customer_user?.customer_user_type_id == 2)
        ?.map((val) => val.customer_user?.user?.surname);
      for (let i in fname) {
        fullname[i] = fname[i] + " " + lname[i];
      }
      return fullname?.length ? fullname.join(", ") : null || "--";
    },
  },
};
</script>
<style scoped>
.text-gray {
  color: darkgray !important ;
}
</style>
