<template>
  <div class="mt-2">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">Details</h6>
    </div>
    <CRow class="pb-2">
      <CCol>
        <CRow class="profileView">
      <CCol lg="4">
        <label class="text-gray">Full Time</label>
      </CCol>
      <CCol lg="8"> 
        <span class="text-black">{{
          profile.full_time | firstLetterCaps
        }}</span>
      </CCol>
        </CRow>
        <CRow class="profileView">
      <CCol lg="4">
         <label class="text-gray">Part Time</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{
          profile.part_time | firstLetterCaps
        }}</span>
      </CCol>
        </CRow>
        <CRow class="profileView">
      <CCol lg="4">
         <label class="text-gray">Permanent Job</label>
      </CCol> 
      <CCol lg="8"> 
        <span class="text-black">{{ profile.permanent_work | yesOrNo }}</span>
      </CCol>
        </CRow>
        <CRow class="profileView">
      <CCol lg="4">
        <label class="text-gray">Temporary Job</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ profile.temporary_work | yesOrNo }}</span>
      </CCol>
        </CRow>
        <CRow class="profileView">
      <CCol lg="4">  
        <label class="text-gray">Working Days (per week)</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ workingDays }}</span>
      </CCol>
        </CRow>
        <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Working hours (per week)</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ workingHours }}</span>
      </CCol>
        </CRow>
        <CRow class="profileView no-line">
      <CCol lg="4"> 
        <label class="text-gray">Hospital/Clinic Based</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ hospital_clinic_based }}</span>
      </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  computed: {
    workingDays() {
      let working_days = this.profile?.job_details?.filter(
        (element) => element.organisation_job_detail?.job_detail_type_id == 1
      );
      return working_days?.length
        ? `${working_days[0].detail_number} day(s)`
        : "--";
    },
    workingHours() {
      let working_hours = this.profile?.job_details?.filter(
        (element) => element.organisation_job_detail?.job_detail_type_id == 2
      );
      return working_hours?.length
        ? `${working_hours[0].detail_number} hour(s)`
        : "--";
    },
    onCallRota() {
      let on_call_rota = this.profile?.job_details?.filter(
        (element) => element.organisation_job_detail?.job_detail_type_id == 6
      );
      return on_call_rota?.length
        ? `${on_call_rota[0].detail_number} hour(s) working for Call-On-Rota`
        : "--";
    },
    hospital_clinic_based() {
      return (
        this.getJobDetailsById?.organisation?.organisation_type
          ?.organisation_type || "--"
      );
    },
  },
  filters: {
    yesOrNo(data) {
      if (data != null) return data ? "Yes" : "No";
      return "N/A";
    },
    firstLetterCaps(data) {
      if (data == "n/a" || data == null) return "N/A";
      return data.charAt(0).toUpperCase() + data.slice(1);
    },
  },
};
</script>
<style scoped>
.text-gray {
  color: darkgrey !important;
}
</style>
