<template>
  <div>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Main Speciality</CCol>
      <CCol col="7" class="text-black">{{ mainSpeciality  }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Years In Speciality</CCol>
      <CCol col="7" class="text-black">{{ mainSpecialityExperience }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Other Speciality Areas</CCol>
      <CCol col="7" class="text-black">{{ otherSpecialities }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Special Interest</CCol>
      <CCol col="7" class="text-black">{{ specilaInterest }}</CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Eligibility</CCol>
      <CCol col="7" class="text-black">{{ pqrLevels }}</CCol>
    </CRow>
    <CRow class="pb-2" v-if="isFirstQualification">
      <CCol col="5" class="text-gray">First Qualification</CCol>
      <CCol col="7" class="text-black">
        {{ firstQualificationName }}
      </CCol>
    </CRow>
    <CRow class="pb-2" v-if="isFirstQualification">
      <CCol col="5" class="text-gray">Year & Country Obtained</CCol>
      <CCol col="7" class="text-black">
        {{ firstQualificationYear }} & {{ firstQualificationCountry }}
      </CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Highest Medical Qualification</CCol>
      <CCol col="7" class="text-black">
        {{
          candidate.highest_qualification_acronym
            ? candidate.highest_qualification_acronym
            : candidate.highest_qualification_name
        }}
      </CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Year & Country Obtained</CCol>
      <CCol col="7" class="text-black">
        {{ highestQualificationYear }} & {{ highestQualificationCountry }}
      </CCol>
    </CRow>
    <CRow class="pb-2">
      <CCol col="5" class="text-gray">Non Medical Qualifications</CCol>
      <CCol col="7" class="text-black">{{ nonMedicalQuals }}</CCol>
    </CRow>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    candidate: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "getCandidateSpecialInterest",
      "getCandidateNonMedicalQuals"
    ]),
    type() {
      return this.candidate?.candidate_type?.candidate_type || "--";
    },
    pqrLevels() {
      if (
        Array.isArray(this.candidate.pqr_levels) &&
        this.candidate?.pqr_levels?.length
      ) {
        const pqr_levels = this.candidate?.pqr_levels[0];
        return pqr_levels?.pqr_detail?.level?.candidate_level;
      }
      return this.candidate.level_name || "--";
    },
    highestQualification() {
      let highestQualification = null;
      this.candidate.qualifications?.length &&
        this.candidate.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
            return true;
          }
          return false;
        });
      return highestQualification;
    },
    highestQualificationCountry() {
      return this.highestQualification?.country_name || null;
    },
    highestQualificationYear() {
      return this.highestQualification?.year_awarded;
    },
    mainSpeciality() {
      return _(this.getCandidateSpecialInterest)
        .filter((speciality) => {
          return speciality.main == true
        })
        .map((speciality)=> {
          return speciality.speciality?.speciality
        })
        .value()
        .toString() || this.candidate?.speciality?.speciality || "--"
    },
    mainSpecialityExperience() {
      return _(this.getCandidateSpecialInterest)
        .filter((speciality) => {
          return speciality.main == true
        })
        .map((speciality)=> {
          return speciality.experience_in_years
        })
        .value()
        .toString() || "--"
    },
    otherSpecialities() {
      return _(this.getCandidateSpecialInterest)
        .filter((speciality) => {
          return speciality.main != true && !speciality.other_special_interest && !speciality.special_interest
        })
        .map((speciality) => {
          return speciality.speciality
            ? speciality.speciality?.speciality
            : speciality?.other_speciality
        })
        .value()
        .join(', ') || "--"
    },
    specilaInterest() {
      return _(this.getCandidateSpecialInterest)
        .filter((speciality) => {
          return speciality.other_special_interest || speciality.special_interest
        })
        .map((speciality) => {
          return speciality.special_interest
            ? speciality.special_interest
            : 'Other - ' + speciality.other_special_interest
        })
        .value()
        .join(', ') || "--"
    },
    firstQualification() {
      let firstQualification = null;
      this.candidate.qualifications?.length &&
        this.candidate.qualifications.some((e) => {
          if (!e.main) {
            firstQualification = e;
            return true;
          }
          return false;
        });
      return firstQualification;
    },
    isFirstQualification() {
      return _(this.candidate.qualifications).filter((qual) => {
          return qual.main != true
        })
        .value().length ? true : false
    },
    firstQualificationName() {
      return this.firstQualification.qualification.qualification || null
    },
    firstQualificationCountry() {
      return this.firstQualification.country_name || null
    },
    firstQualificationYear() {
      return this.firstQualification.year_awarded || null
    },
    nonMedicalQuals() {
      return _(this.getCandidateNonMedicalQuals)
        .map((qualification) => {
          return qualification.non_medical_qual?.name
            ? qualification.non_medical_qual?.name
            : 'Other - ' + qualification.others_name
        })
        .value()
        .join(', ') || "--" 
    }
  } 
}
</script>