<template>
  <div>
    <CModal color="primary" :show.sync="isShowPopup" :close-on-backdrop="false" size="lg">
      <template #header>
        <h6 class="modal-title">Consultant Details Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
        >Close</CButton>
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()">Save</CButton>
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <ValidationObserver ref="editConsultantDetails" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="editConsultantDetails">
            <CRow>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="required col-lg-12 col-md-12">Candidate Type</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidateType"
                        :value="jobs.candidateType"
                        @input="handleChangeSelect"
                        :options="
                          options && options['candidateType']
                            ? options['candidateType']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="!isAHP">
                <CRow class="mb-3">
                  <label name="lbl_first_name" class="required col-lg-12 col-md-12">Level</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="level_ids"
                        :value="jobs.level_ids"
                        @input="handleChangeSelect"
                        :options="
                          options && options['level_id']
                            ? options['level_id']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="required col-lg-12 col-md-12">
                    {{
                    isAHP ? "Category of AHP" : "Speciality"
                    }}
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="speciality"
                        :value="jobs.speciality"
                        @input="handleChangeSelect"
                        @change="resetSplInterest"
                        :options="
                          options && options['speciality']
                            ? options['speciality']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol
                md="6"
                v-if="
                  options['subSpeciality'] && options['subSpeciality'].length
                "
              >
                <CRow class="mb-3">
                  <label name="lbl_surname" class="col-lg-12 col-md-12">
                    {{
                    isAHP ? "Sub Type / License" : "Sub Speciality"
                    }}
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="sub_speciality_id"
                      :value="jobs.sub_speciality_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['subSpeciality']
                          ? options['subSpeciality']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <!-- <CRow>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">Special Interest(s)</label>
                  <div class="col infoicon-multi-select">
                    <Select
                      name="special_interest_ids"
                      :value="jobs.special_interest_ids"
                      @input="handleChangeSelect"
                      :options="
                        options && options['special_interest']
                          ? options['special_interest']
                          : []
                      "
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>-->
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import AddJob from "../../AddJobs/AddJob";
extend("required", { ...required, message: "This field is required" });

export default {
  mixins: [AddJob],
  props: {
    jobInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profileData: {},
      payload: {},
      jobs: {},
    };
  },
  computed: {
    isFetching() {
      if (Object.keys(this.jobInfo).length) {
        let jobData = this.jobInfo;
        this.jobs = {
          ...this.jobInfo,
          organisation: jobData?.organisation
            ? {
                label: jobData?.organisation.name,
                code: jobData?.organisation.organisation_id,
                country_id: jobData?.organisation?.country_id,
              }
            : null,
          candidateType: jobData?.candidate_type
            ? {
                label: jobData?.candidate_type?.candidate_type,
                code: jobData?.candidate_type?.candidate_type_id,
              }
            : null,
          level_ids: jobData?.level_ids
            ? this.getLevelForSelectedId(jobData?.level_ids)
            : null,
          speciality: jobData?.specialities
            ? jobData?.specialities
                .filter(
                  (val) => val.is_current == true || val.is_current == null
                )
                ?.map((val) => {
                  return {
                    label: val.speciality?.speciality,
                    code: val.speciality_id,
                  };
                })[0]
            : null,
          sub_speciality_id: jobData?.specialities[0]?.sub_speciality
            ?.sub_speciality
            ? {
                label: jobData?.specialities[0]?.sub_speciality?.sub_speciality,
                code: jobData?.specialities[0]?.sub_speciality_id,
              }
            : null,
          special_interest_ids: jobData?.specialities[0]?.special_interest_ids
            ? this.getSpecialInterestForSelectedId(
                jobData?.specialities?.filter(
                  (val) => val.is_current == true || val.is_current == null
                )[0]?.special_interest_ids
              )
            : null,
          job_speciality_id: jobData?.specialities?.filter(
            (val) => val.is_current == true || val.is_current == null
          )[0]?.job_speciality_id,
        };
      }
      return true;
    },
    options() {
      return {
        candidateType: this.candidateType || [],
        level_id:
          this.candidateLevelByTypeAndCountry({
            candidateType: this.jobs?.candidateType?.code,
            country_id: null,
          }) || [],
        speciality:
          this.specialityByTypeAndCountry({
            candidateType: this.jobs?.candidateType?.code,
            country_id: null,
          }) || [],
        subSpeciality:
          this.subSpecialityFilterBySpeciality({
            speciality: this.jobs?.speciality?.code,
          }) || [],
        // special_interest:
        //   this.specialInterestFilterBySpeciality({
        //     speciality: this.jobs?.speciality?.code,
        //   }) || [],
      };
    },
    isNurse() {
      return this.jobs.candidateType?.code == 2 ? true : false;
    },
    isAHP() {
      return this.jobs.candidateType?.code == 3 ? true : false;
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.editConsultantDetails.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (Object.keys(this.payload).length) {
        let finalPayload = {
          candidate_type_id: this.jobs?.candidateType.code,
          level_ids: this.jobs?.level_ids?.map((val) => val.code),
          speciality: {
            job_speciality_id: this.jobs?.job_speciality_id,
            speciality_id: this.jobs.speciality?.code,
            sub_speciality_id: this.jobs.sub_speciality_id?.code || null,
            special_interest_ids: this.jobs?.special_interest_ids?.map(
              (val) => val.code
            ),
          },
          organisation_id: this.jobs?.organisation?.code,
          job_id: this.jobs?.job_id,
        };
        this.modalCallBack(true, finalPayload);
      } else {
        this.modalCallBack(false);
      }
    },
    resetSplInterest() {
      this.jobs.special_interest_ids = [];
      this.jobs.sub_speciality_id = null;
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
};
</script>
