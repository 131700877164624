<template>
  <div>
    
    <p class="text-center text-gray h5">Comments on Job</p>
  </div>
</template>
<script>
import moment from "moment";
import { Role, getScope } from "@/helpers/helper";
import { mapGetters, mapActions } from "vuex";
export default {
 
  mounted() {
    let isMobile = window.matchMedia("only screen and (max-width: 760px)")
      .matches;
    this.isMobile = isMobile;
  },
};
</script>
<style lang="scss" scoped>
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 50px;
  }
}
.cursor-action {
  cursor: pointer;
}
.candidate-card:hover {
  background-color: #fdf1f64d;
}
.candidate-card.candidate-active {
  background-color: #fdf1f6;
  box-shadow: 0 4px 8px 0 rgba(253, 241, 246, 0.15),
    0 6px 20px 0 rgba(253, 241, 246, 0.14);
}
.action-icon {
  position: absolute;
  top: 1.24em;
  right: 1.4em;
  z-index: 1;
}
</style>