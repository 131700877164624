<template>
  <div class="top-line">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">Contact Information</h6>
      <div class="d-flex justify-content-end action-wrapper" v-if="isCandidate">
        <!-- <a
          class="px-2 btn"
          v-if="currentUserRole === Role.customerAdmin"
          @click="editContactInformation"
        >
          <i class="fas fa-pencil-alt" alt="pencil" />
        </a> -->
      </div>
    </div>
    <CRow v-if="contactName" class="ml-1 m-0">
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-start sticky-header">
          <h6 class="contact d-block">{{ contactName }}</h6>
        </div>
      </div>
    </CRow>
    <CRow class="pb-2 ml-1 m-0">
      <div class="d-flex justify-content-between">
        <span>
          <em class="far fa-envelope mr-2"></em>
        </span>
        <div class="d-flex justify-content-start sticky-header">
          <span>
            <span class="text-black d-block">{{ email }}</span>
            <span class="text-black d-block" v-if="alternate_email">{{
              alternate_email
            }}</span>
          </span>
        </div>
      </div>
    </CRow>
    <CRow class="pb-2 ml-1 m-0">
      <div class="d-flex justify-content-between">
        <span>
          <em class="fa fa-phone mr-2"></em>
        </span>
        <div class="d-flex justify-content-start sticky-header">
          <span>
            <span class="text-black d-block">{{ phone }}</span>
            <span class="text-black d-block" v-if="alternate_phone">{{
              alternate_phone
            }}</span>
          </span>
        </div>
      </div>
    </CRow>
    <!-- <CRow class="pb-2 m-0">
      <span>
        <em class="fab fa-whatsapp mr-2"></em>
        <span class="text-black">{{ whatsapp }}</span>
      </span>
    </CRow>
    <div v-if="isCandidate">
      <CRow class="pb-2 m-0">
        <span>
          <em class="fab fa-skype mr-2"></em>
          <span class="text-black">{{ skype_id }}</span>
        </span>
      </CRow>
      <CRow class="pb-2 m-0" v-if="isCandidate">
        <div class="d-flex justify-content-between">
          <span>
            <em class="fas fa-map-marker-alt mr-2"></em>
          </span>
          <div class="d-flex justify-content-start sticky-header">
            <span>{{ currentLocation || "--" }}</span>
          </div>
        </div>
      </CRow>
      <div class="center py-2">
        <CButton color="primary" @click="openCommentModal()"> Private Comments ({{ getComments.length }}) </CButton>
      </div>
    </div> -->
    <ContactInformationEdit
      :jobInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="contactInformationModalCallBack"
      v-if="EditModal.isShowPopup"
    />
    <JobCommentModal
      v-if="commentModal.isShowPopup"
      :isShowPopup="commentModal.isShowPopup"
      :jobID="commentModal.jobID"
      :job="commentModal.job"
      @commentModalCallBack="commentModalCallBack"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import ContactInformationEdit from "@/containers/JobsDetailedView/EditForm/ContactInformationEdit";
import JobCommentModal from "@/containers/JobsDetailedView/JobCommentModal";
export default {
  props: {
    isHospital: {
      type: Boolean,
      default: false,
    },
    isSideBar: {
      type: Boolean,
      default: false,
    },
    isCandidate: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
    },
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      commentModal: {
        isShowPopup: false,
        jobID: null,
        job: null,
      },
    };
  },
  components: {
    ContactInformationEdit,
    JobCommentModal,
  },
  computed: {
    ...mapGetters([
      "getSelectedCandidateDataForJobsList",
      "getJobDetailsById",
      "locationCountryInfo",
      "getJobCommonComments",
    ]),
    contactName() {
      return this.profile?.job_contact?.name
        ? this.profile?.job_contact?.name
        : this.profile?.organisation?.main_contact?.name;
    },
    email() {
      return this.profile?.job_contact?.email
        ? this.profile?.job_contact?.email
        : this.profile?.organisation?.main_contact?.email || "--";
    },
    alternate_email() {
      return !this.profile?.job_contact
        ? this.profile?.organisation?.main_contact?.alt_email
        : null;
    },
    phone() {
      return this.profile?.job_contact
        ? this.profile?.job_contact?.phone_no
          ? this.profile?.job_contact?.phone_no?.replace("^", "-")
          : "--"
        : this.profile?.organisation?.main_contact?.phone_no?.replace(
            "^",
            "-"
          ) || "--";
    },
    alternate_phone() {
      return this.profile?.job_contact
        ? this.profile?.job_contact?.alt_phone_no
          ? this.profile?.job_contact?.alt_phone_no?.replace("^", "-")
          : null
        : this.profile?.organisation?.main_contact?.alt_phone_no?.replace(
            "^",
            "-"
          ) || null;
    },
    whatsapp() {
      return "--";
      // return this.profile?.organisation?.main_contact?.prefer_whats_app?.replace("^","-") || "--"
    },
    skype_id() {
      return this.profile?.organisation?.main_contact?.skype || "--";
    },
    address_line_1() {
      return this.profile?.organisation?.main_contact?.address || "--";
    },
    currentLocation() {
      return (
        this.locationCountryInfo &&
        this.locationCountryInfo.length &&
        this.profile?.organisation?.country?.country_id &&
        this.locationCountryInfo.filter(
          (val) => val.code === this.profile?.organisation?.country?.country_id
        )[0].label
      );
    },
    getComments() {
      return this.getJobCommonComments;
    },
  },
  methods: {
    ...mapActions([
      "updateCandidate",
      "initFetchContactInfoOptions",
      "fetchJobCommonComments",
    ]),
    editContactInformation() {
      this.EditModal.isShowPopup = true;
    },
    contactInformationModalCallBack(action, data) {
      if (action === false) this.EditModal.isShowPopup = false;
      if (action === true && data != null) {
        const { customer_uid } = this.profile;
        // let appentAction = this.updateCandidate({ data, customer_uid });
        // Promise.all([appentAction]).then((res) => {
        //   if (res)
        this.EditModal.isShowPopup = false;
        // });
      }
    },
    openCommentModal() {
      this.commentModal.jobID = this.profile?.job_id;
      this.commentModal.job = this.profile;
      this.commentModal.isShowPopup = true;
    },
    commentModalCallBack() {
      this.commentModal.isShowPopup = false;
    },
  },
  mounted() {
    this.initFetchContactInfoOptions();
    this.fetchJobCommonComments(this.profile?.job_uuid);
  },
};
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
}
.contact {
  color: #3c4b64;
  font-weight: 600;
}
</style>
