<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Contact Information Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <ValidationObserver ref="editContactInformation" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="editContactInformation">
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label name="lbl_email" class="col-lg-12 col-md-12">Email</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextInput
                        name="email"
                        :value="profile.email"
                      />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label name="lbl_alternate_email" class="col-lg-12 col-md-12">Phone</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                      <TextInput
                        id="txt1"
                        ref="txt1"
                        name="alternate_email"
                        :value="profile.alternate_email"
                        @change="handleInput"
                      />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3" >
                  <label name="lbl_phone" class="col-lg-12 col-md-12">Alternate Phone</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="phone"
                      :rules="{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInputwithCountry
                        name="phone"
                        :value="profile.phone"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        @change="handleInput"
                        :error="errors[0]"
                        ref="phoneNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label name="lbl_alternate_phone" class="col-lg-12 col-md-12"
                    >WhatsApp</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="whatsapp"
                      :rules="{
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInputwithCountry
                        name="whatsapp"
                        :value="profile.whatsapp"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        @change="handleInput"
                        :error="errors[0]"
                        ref="whatsappNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12">Skype</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextInput
                      name="skype_id"
                      :value="profile.skype_id"
                      @change="handleInput"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label name="lbl_linkedin_address" class="col-lg-12 col-md-12"
                    >Location</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="country_id"
                      :value="profile.country_id"
                      :options="
                        options && options.countryInfo
                          ? options.countryInfo
                          : []
                      "
                      @change="handleChangeSelect"
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, numeric } from "vee-validate/dist/rules";
import { regex } from "vee-validate/dist/rules";
import EditSection from "./EditSection";
import { mapActions, mapGetters } from "vuex";
import profile from '@/containers/AddJobs/profile'
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";

extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
export default {
  mixins: [EditSection, profile],
  components: {
    PhoneInputwithCountry
  },
  props: {
    jobInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile: {},
    };
  },
  computed: {
    ...mapGetters([
      "locationCountryInfo",
      "dialingCodeWithCountryCode",
      "isFetchingFilter",
    ]),
    options() {
      return {
        countryInfo: this.locationCountryInfo || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
      };
    },
    isFetching() {
      if (
        isObject(this.jobInfo) &&
        !isEmptyObjectCheck(this.jobInfo) &&
        !this.isFetchingFilter
      ) {
        let profileData = this.jobInfo;
        this.profile = {
          ...profileData,
          customer_uid: profileData?.customer_uid,
          job_id: profileData?.job_id,
          email: profileData?.organisation?.main_contact?.email !== null ? profileData?.organisation?.main_contact?.email  : "",
          phone: profileData?.organisation?.main_contact.phone_no !== null ? profileData?.organisation?.main_contact?.phone_no : "",
          alternate_phone:
            profileData?.organisation?.main_contact?.alt_phone_no !== null
              ? profileData?.organisation?.main_contact?.alt_phone_no
              : "",
          whatsapp: 
            profileData?.organisation?.main_contact?.prefer_whats_app !== null  
            ? profileData?.organisation?.main_contact?.prefer_whats_app 
            : "",
          skype_id: profileData?.organisation?.main_contact?.skype !== null ? profileData?.organisation?.main_contact?.skype : "",
          country_id: profileData?.organisation?.main_contact?.location_id 
            ? this.locationCountryInfo.filter(
                (val) => val.code === profileData?.organisation?.main_contact?.location_id 
              )[0]
            : null,
        };
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["initFetchContactInfoOptions", "showToast"]),

    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        } else if (name === hasError.whatsapp) {
          this.$refs.whatsapp.errors.clear;
          this.$refs.whatsapp.reset();
        } else if (name === hasError.alternate_phone) {
          this.$refs.alternate_phone.errors.clear;
          this.$refs.alternate_phone.reset();
        }
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.editContactInformation.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return; 
      }
      this.payload = {
        ...this.payload,
        candidate_uid: this.profile?.candidate_uid,
        alternate_email: this.profile?.alternate_email,
        phone: this.profile?.phone,
        alternate_phone: this.profile?.alternate_phone,
        whatsapp: this.profile?.whatsapp,
        skype_id: this.profile?.skype_id,
      };
      this.modalCallBack(true, this.payload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
  mounted() {
    this.initFetchContactInfoOptions();
    this.profile = { ...this.jobInfo }
  },
};
</script>