<template>
  <div class="mt-2">
    <LoadingOverlay v-if="isFetching" />
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">
        Preferences
        <!-- <CIcon
          name="cil-info"
          class="hinticon-multi-select"
          v-c-tooltip="
            '<strong> See Details Section below for Additional Details</strong>'
          "
        />
        <em
          class="fas fa-angle-double-down"
          style="cursor: pointer"
          @click="$parent.goToPreferenceSection()"
        ></em> -->
      </h6>
      <!-- <div class="d-flex justify-content-end action-wrapper">
        <a
          class="px-2 btn"
          v-if="currentUserRole === Role.customerAdmin"
          @click="editPreferencesDetail"
        >
          <em class="fas fa-edit" alt="Edit" />
        </a>
      </div> -->
    </div>

    <CRow class="pb-2">
      <!-- <CCol lg="6">
        <label class="text-gray">Qualification Region</label>
        <span class="text-black">{{ qualificationRegions }}</span>
      </CCol>
      <CCol lg="6">
        <label class="text-gray">Qualification Country</label>
        <span class="text-black">{{ qualificationCountries }}</span>
      </CCol> -->
      <CCol>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Regional license</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{
              profile.regional_license | reg_license
            }}</span>
          </CCol>
        </CRow>
        <!-- <CCol lg="6">
        <label class="text-gray">Nationality</label>
        <span class="text-black">{{ nationalityLabels }}</span>
      </CCol> -->
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Gender</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ gender }}</span>
          </CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Minimum Experience</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{
              profile.min_yrs_of_exp ? `${profile.min_yrs_of_exp} years` : "N/A"
            }}</span>
          </CCol>
        </CRow>
        <CRow class="profileView no-line">
          <CCol lg="4">
            <label class="text-gray">Language(s)</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ languages }}</span>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <PreferencesEdit
      :jobInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="preferenceModalCallBack"
      v-if="EditModal.isShowPopup"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import { mapActions } from "vuex";
import PreferencesEdit from "./EditForm/PreferencesEdit";
import AddJob from "../AddJobs/AddJob";

export default {
  mixins: [AddJob],
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  components: {
    PreferencesEdit,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      isFetching: false,
    };
  },
  computed: {
    qualificationRegions() {
      let regions = this.getRegionNameForSelectedId(
        this.profile?.qualification_region_ids
      ).map((val) => val.label);
      return regions?.length ? regions.join(", ") : null || "All";
    },
    qualificationCountries() {
      let countries = this.getCountryNameForSelectedId(
        this.profile?.qualification_country_ids
      ).map((val) => val.label);
      return countries?.length ? countries.join(", ") : null || "All";
    },
    gender() {
      return this.profile.gender ? this.profile.gender.gender : "Any";
    },
    nationalityLabels() {
      const comments = this.profile?.countries_included?.map(
        (nationality) => nationality?.country?.country_name
      );
      const nationality = comments.length ? comments.join(", ") : null;
      return nationality || "All";
    },
    languages() {
      const comments = this.getLanguageForSelectedId(
        this.profile?.language_ids
      ).map((qualification) => qualification.label);
      return comments?.length ? comments.join(", ") : null || "--";
    },
  },
  filters: {
    reg_license(data) {
      if ((data == "n/a") | (data == null)) return "N/A";
      return data.charAt(0).toUpperCase() + data.slice(1);
    },
  },
  methods: {
    ...mapActions([
      "updateJobByDetailPage",
      "getJobById",
      "initFetchPrefrencesOptions",
    ]),
    editPreferencesDetail() {
      this.isFetching = true;
      this.initFetchPrefrencesOptions({
        qualification_regions: this.profile?.qualification_region_ids,
        organisation_id: this.profile?.organisation?.organisation_id,
      }).then((res) => {
        this.isFetching = false;
        this.EditModal.isShowPopup = true;
      });
    },
    preferenceModalCallBack(action, data) {
      if (!action) this.EditModal.isShowPopup = false;
      if (action && data != null) {
        let appendAction = [];
        const { customer_uid, job_id, job_uuid } = this.profile;
        const { countries_included, organisation_id, ...rest } = data;
        if (countries_included || this.profile.countries_included) {
          let createCountry = [];
          let deleteCountry = [];
          let existingCountries = this.profile?.countries_included;
          if (countries_included?.length) {
            countries_included.forEach((ele) => {
              if (existingCountries?.length) {
                existingCountries.some(
                  (val) => ele.code == val.include_country_id
                )
                  ? null
                  : createCountry.push(ele);
                return;
              }
              createCountry.push(ele);
            });
          }
          if (existingCountries?.length) {
            existingCountries.forEach((ele) => {
              if (countries_included?.length) {
                countries_included.some(
                  (val) => val.code == ele.include_country_id
                )
                  ? null
                  : deleteCountry.push(ele);
                return;
              }
              deleteCountry.push(ele);
            });
          }

          if (createCountry?.length || deleteCountry?.length) {
            createCountry.forEach((item) => {
              appendAction = this.createJobCountry({
                job_id,
                customer_uid,
                country: item,
              });
            });
            deleteCountry.forEach(({ job_country_include_id }) => {
              appendAction = this.deleteJobCountryById({
                job_id,
                customer_uid,
                job_country_include_id,
              });
            });
          }
        }
        appendAction = this.updateJobByDetailPage({
          organisation_id,
          customer_uid,
          ...rest,
        });
        Promise.all([appendAction]).then((res) => {
          this.getJobById(job_uuid);
          this.EditModal.isShowPopup = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.text-gray {
  color: darkgrey !important;
}
</style>
