<template>
  <div class="job_comments">
    <div v-if="JobComments.length">
      <div v-for="(data, index) in JobComments" :key="index" class="pr-3">
        <blockquote :class="'speech-bubble'">
          <p>{{ data.comments }}</p>
          <label class="note"
            ><time>{{ data.created_on | formatDate }}</time>
          </label>
          <a class="hand-icon btn action" @click="deleteComment(data)">
            <i class="fas fa-trash"></i>
          </a>
        </blockquote>
      </div>
    </div>
    <p v-else class="text-center text-gray h5">No Comments Found</p>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";

export default {
  name: "JobComments",
  props: {
    jobID: {
      type: Number,
      default: null,
    },
    job: {
      type: Object,
      default: null,
    },
  },
  components: { Modal },
  data() {
    return {
      comments: [],
      deleteConfirmModal: {
        modalColor: "primary",
        modalTitle: "Delete Comment",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      comment_id: null,
    };
  },
  computed: {
    ...mapGetters(["getJobCommonComments"]),
    JobComments() {
      return _.orderBy(this.getJobCommonComments, ["created_on"], ["desc"]);
    },
  },
  methods: {
    ...mapActions(["fetchJobCommonComments", "deleteJobComment"]),
    deleteComment(data) {
      this.deleteConfirmModal.modalContent = `Are you sure to delete this comment?`;
      this.comment_id = data.id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const { job_uuid } = this.job;
        let payload = {
          job_id: this.jobID,
          job_uuid,
          comment_id: this.comment_id,
        };
        this.deleteJobComment(payload).then((res) => {
          this.fetchJobCommonComments(job_uuid);
        });
      }
      this.deleteConfirmModal.isShowPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/// REQUIRED
$primary: map-get($theme-colors, primary);
$secondary: map-get($theme-colors, secondary);
/// REQUIRED
$gutter: 20px;
$caret-unit: 25px;
$bubble-bg: #5dacb5;
$bubble-bg-alt: #c0c0c0;
$bubble-color: white;
$cite-color: rgb(37, 37, 37);

@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    "top": "bottom",
    "right": "left",
    "bottom": "top",
    "left": "right",
    "center": "center",
    "ltr": "rtl",
    "rtl": "ltr",
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append(
        $opposite-directions,
        unquote(map-get($direction-map, $direction))
      );
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}

/// Unit Removal
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/// Triangle Mixin
/// ==========================================================

@mixin caret($point, $border-width, $color) {
  $opposite: opposite-direction($point);
  border: $border-width solid transparent;
  border-#{$opposite}: $border-width solid $color;
  border-#{$point}: 0;
  height: 0;
  width: 0;
}

/// Component
.speech-bubble {
  // filter: drop-shadow(-1px -1px 2px rgba(black, 0.1))
  //   drop-shadow(1px 2px 2px rgba(black, 0.15));
  margin: 1rem;
  margin-bottom: ($gutter * 2);
  padding: 1.5rem 2rem;
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  color: #383838;

  &::before {
    @include caret(bottom, ($caret-unit / 2), $bubble-bg);
    border-top-width: $caret-unit;
    content: "\0020";
    display: block;
    position: absolute;
    left: 47px;
    bottom: -27px;
    border-top-color: $bubble-color;
    transform-origin: center;
    transform: rotate(90deg) skew(-(strip-unit($caret-unit)) + deg)
      translateY($caret-unit / 1.5);
  }

  &::after {
    @include caret(bottom, ($caret-unit / 2), $bubble-bg);
    border-top-width: $caret-unit;
    content: "";
    display: block;
    position: absolute;
    left: 44.9px;
    bottom: -21px;
    border-top-color: $bubble-color;
    transform-origin: center;
    transform: rotate(90deg) skew(-(strip-unit($caret-unit)) + deg)
      translateY($caret-unit / 1.5);
  }
}
.speech-bubble .subject {
  font-weight: bold;
  clear: both;
  font-style: italic;
}
.speech-bubble .note {
  position: absolute;
  bottom: -2rem;
  left: 4.5rem;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: $cite-color;

  time {
    color: #000;
    margin-right: 5px;
  }
}
.I {
  border: 2px solid $bubble-bg-alt;
}
.O {
  border: 2px solid $bubble-bg;
}
.I::before {
  border-top: 12.5px solid $bubble-bg-alt;
  border-top-width: 25px;
}
.O::before {
  border-top: 12.5px solid $bubble-bg;
  border-top-width: 25px;
}
.attachment-item a {
  cursor: pointer;
  white-space: nowrap;
  margin: 8px;
  text-decoration: underline;
}
</style>