<template>
  <div class="comment-modal">
    <CModal color="primary" :show.sync="isShowPopup" :close-on-backdrop="false">
      <template #header>
        <h6 class="modal-title">Comment</h6>
        <CButtonClose v-if="isBtnHide" @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          v-if="isBtnHide"
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <div>
        <ValidationObserver ref="candidateComment" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            name="candidateComment"
          >
            <CRow>
              <CCol lg="12">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Comment</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextareaInput
                        name="comments"
                        :value="profile.comments"
                        label="comments"
                        @input="handleInput"
                        :error="errors[0]"
                      ></TextareaInput>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    isBtnHide: {
      type: Boolean,
      default: true,
    },
    candidate_id: {
      type: Number,
      default: null,
    },
  },
  components: {
    TextareaInput,
  },
  data() {
    return {
      profile: {},
      payload: {},
    };
  },
  methods: {
    handleInput(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
    },
    modalCallBack(action, payload) {
      this.$emit("commentModalCallBack", action, payload);
      
    },
    async onSubmit() {
      const isValid = await this.$refs.candidateComment.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.modalCallBack(true, this.payload);
      this.$parent.isBtnHide = true;
    },
  },
};
</script>