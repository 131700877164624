<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Advertising Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <ValidationObserver ref="EditAdvertisingInfo" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            name="EditAdvertisingInfo"
          >
            <CRow>
              <CCol md="6" class="mb-3">
                <CRow>
                  <label class="col-lg-12 col-md-12"
                    >Advertising Location</label
                  >
                  <div class="col infoicon-multi-select">
                    <Select
                      name="advertisement_location"
                      :value="jobs.advertisement_location"
                      @input="handleChangeSelect"
                      :options="
                        options && options['advertisement_location']
                          ? options['advertisement_location']
                          : []
                      "
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AddJob from "@/containers/AddJobs/AddJob";
export default {
  mixins: [AddJob],
  props: {
    jobInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profileData: {},
      payload: {},
      jobs: {},
    };
  },
  computed: {
    isFetching() {
      if (Object.keys(this.jobInfo).length) {
        let jobData = this.jobInfo;
        this.jobs = {
          ...this.jobInfo,
          organisation: jobData?.organisation
            ? {
                label: jobData?.organisation.name,
                code: jobData?.organisation.organisation_id,
              }
            : null,
          advertisement_location: jobData?.advertisement_location
            ? this.getAdLocationForSelectedID(jobData?.advertisement_location)
            : null,
        };
      }
      return true;
    },
    options() {
      return {
        advertisement_location: [
          { label: "Job Board", code: "job_board" },
          { label: "Social Media", code: "social_media" },
        ],
      };
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.EditAdvertisingInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      if (Object.keys(this.payload).length) {
        let finalPayload = {
          job_id: this.jobs?.job_id,
          customer_uid: this.jobs?.customer_uid,
          organisation_id: this.jobs.organisation?.code,
          advertisement_location: this.jobs?.advertisement_location?.length
            ? this.jobs?.advertisement_location?.map((val) => val.code)
            : null,
        };
        this.modalCallBack(true, finalPayload);
      } else {
        this.modalCallBack(false);
      }
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
};
</script>
