<template>
  <div class="pb-2">
    <div>
      <div
        class="d-flex align-items-center documentScrolling document-table border"
      >
        <table class="table">
          <thead>
            <tr>
              <th class="nowrap w-25">Type</th>
              <th class="nowrap">Description</th>
              <th class="nowrap">Name</th>
              <th class="nowrap">Date Uploaded</th>
              <th class="nowrap">Preview</th>
            </tr>
          </thead>
          <tbody class="text-black">
            <tr v-for="data in resumeDocument" :key="data.document_id">
              <td>{{ data.lable || "--" }}</td>
              <td class="text-justify">
                {{ data.comments ? data.comments : "--" }}
              </td>
              <td class="candidate-file-name">{{ data.name || "--" }}</td>
              <td>{{ data.updatedDate }}</td>
              <td>
                <a
                  class="hand-icon"
                  v-if="data && data.link"
                  @click="preview(data)"
                >
                  <i class="fas fa-eye ml-3"></i>
                </a>
              </td>
            </tr>
            <tr v-for="data in documents" :key="data.document_id">
              <td>{{ data.lable || "--" }}</td>
              <td class="text-justify">
                {{ data.comments ? data.comments : "--" }}
              </td>
              <td class="candidate-file-name">{{ data.name || "--" }}</td>
              <td>{{ data.updatedDate }}</td>
              <td>
                <a
                  class="hand-icon"
                  v-if="data && data.link"
                  @click="preview(data)"
                >
                  <i class="fas fa-eye ml-3"></i>
                </a>
              </td>
            </tr>
            <tr v-if="documents.length === 0">
              <td colspan="6">
                <h6 v-if="resumeDocument.length" class="text-center text-gray">
                  No Further Documents to Preview
                </h6>
                <h6 v-else class="text-center text-gray">
                  No Documents to Preview
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PreviewModal
        :modalTitle="previewModal.modalTitle"
        :modalColor="previewModal.modalColor"
        :isShowPopup="previewModal.isShowPopup"
        :buttons="previewModal.buttons"
        :modalCallBack="modalCallBack"
        :isPreview="previewModal.isPreview"
        :iframe="iframe"
        :size="previewModal.size"
        :closeOnBackdrop="previewModal.closeOnBackdrop"
      />
    </div>
  </div>
</template>
<script>
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import { BASE_URL } from "@/service_urls";
import { mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck, appendAccessToken } from "@/helpers/helper";
import moment from "moment";

export default {
  components: { PreviewModal },
  data() {
    return {
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getUploadedDocument", "getCustomerId"]),
    documents() {
      return (
        (this.getUploadedDocument &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) => document_type_id !== 1)
            .map(
              ({
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_uuid,
                comments,
              }) => {
                return {
                  link: appendAccessToken(`/api/v1/customer/${this.getCustomerId}/file/${candidate_document_uuid}`),
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext.document_ext,
                  document_id: candidate_document_uuid,
                  comments: comments,
                };
              }
            )) ||
        []
      );
    },
    resumeDocument() {
      return (
        (this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) => document_type_id === 1)
            .map(
              ({
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_uuid,
                comments,
              }) => {
                return {
                  link: appendAccessToken(`/api/v1/customer/${this.getCustomerId}/file/${candidate_document_uuid}`),
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext.document_ext,
                  document_id: candidate_document_uuid,
                  comments: comments,
                };
              }
            )) ||
        []
      );
    },
  },
  methods: {
    preview(data) {
      // var _baseUrl = BASE_URL;
      // var _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      let _base_domain = window.location.origin;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = _base_domain + data.link;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(_base_domain + data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          _base_domain +
          data.link +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.preview {
  .iframeclass {
    width: 75%;
  }
  .modalPdfContent {
    height: 350px !important;
    overflow-y: scroll;
  }
  .wrapper {
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
  }
  @media all and (max-width: 480px) {
    .iframeclass {
      width: auto;
    }
  }
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.hand-icon {
  cursor: pointer;
}
</style>