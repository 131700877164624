<template>
  <div>
    <LoadingOverlay v-if="isFetching" />
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">{{ jobTitle }}</h6>
      <!-- <div class="d-flex justify-content-end action-wrapper">
        <a
          class="px-2 btn"
          v-if="currentUserRole === Role.customerAdmin"
          @click="editConsultantDetails"
        >
          <em class="fas fa-edit" alt="Edit" />
        </a>
      </div>-->
    </div>
    <CRow class="pb-2">
      <CCol>
      <CRow class="profileView">
      <CCol lg="4"> 
        <label class="text-gray">Type</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ type }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="!isAHP"> 
      <CCol lg="4">
        <label class="text-gray">Level</label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ levelLabels }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView"> 
      <CCol lg="4">
        <label class="text-gray">
          {{
          isAHP ? "Category of AHP" : "Speciality"
          }}
        </label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ speciality }}</span>
      </CCol>
      </CRow>
      <CRow class="profileView" v-if="sub_speciality"> 
      <CCol lg="4"> 
        <label class="text-gray">
          {{
          isAHP ? "Sub Type / License" : "Sub Speciality"
          }}
        </label>
      </CCol>
      <CCol lg="8">
        <span class="text-black">{{ sub_speciality || "--" }}</span>
      </CCol>
      </CRow>
      </CCol>
      <!-- <CCol lg="6">
        <label class="text-gray">Special Interests</label>
        <span class="text-black">{{ special_interests }}</span>
      </CCol>-->
    </CRow>

    <ConsultantDetailsEdit
      :jobInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="basicInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import ConsultantDetailsEdit from "./EditForm/ConsultantDetailsEdit";
export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  components: {
    ConsultantDetailsEdit,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      isFetching: false,
    };
  },
  computed: {
    ...mapGetters(["getSpecialInterestForSelectedId"]),
    jobTitle() {
      return this.profile?.job_title || "--";
    },
    levelLabels() {
      return this.profile.level_names && this.profile.level_names.length
        ? this.profile.level_names.join(", ")
        : "--";
    },
    type() {
      return this.profile?.candidate_type?.candidate_type || "--";
    },
    speciality() {
      return this.profile?.specialities?.length
        ? this.profile?.specialities?.filter(
            (val) => val.is_current == true || val.is_current == null
          )[0]?.speciality?.speciality
        : "--";
    },
    sub_speciality() {
      return this.profile?.specialities?.length
        ? this.profile?.specialities[0]?.sub_speciality?.sub_speciality
        : null;
    },
    special_interests() {
      const data = this.getSpecialInterestForSelectedId(
        this.profile?.specialities?.filter(
          (val) => val.is_current == true || val.is_current == null
        )[0]?.special_interest_ids
      ).map((specialInterests) => specialInterests.label);
      return data?.length ? data.join(", ") : null || "--";
    },
    location() {
      return this.profile?.location ? this.profile?.location?.location : "--";
    },
    isNurse() {
      return this.profile?.candidate_type?.candidate_type_id == 2
        ? true
        : false;
    },
    isAHP() {
      return this.profile?.candidate_type?.candidate_type_id == 3
        ? true
        : false;
    },
  },
  methods: {
    ...mapActions([
      "updateJobByDetailPage",
      "getJobById",
      "initFetchConsultantInfoOptions",
      "updateJobSpeciality",
    ]),
    editConsultantDetails() {
      this.isFetching = true;
      this.initFetchConsultantInfoOptions().then((res) => {
        this.isFetching = false;
        this.EditModal.isShowPopup = true;
      });
    },
    basicInfoModalCallBack(action, data) {
      if (!action) this.EditModal.isShowPopup = false;
      if (action && data != null) {
        let { speciality, organisation_id, ...rest } = data;
        let { job_id, job_uuid, customer_uid } = this.profile;
        let appendAction = [];
        appendAction.push(
          this.updateJobByDetailPage({
            organisation_id,
            customer_uid,
            ...rest,
          })
        );
        appendAction.push(
          this.updateJobSpeciality({
            job_id,
            customer_uid,
            ...speciality,
          })
        );
        Promise.all([appendAction]).then((res) => {
          this.getJobById(job_uuid);
          this.EditModal.isShowPopup = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.text-gray {
  color: darkgrey !important;
}
</style>
