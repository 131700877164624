<template>
  <div class="job-details px-0 container-md col-lg-10 col-md-10">
    <LoadingOverlay v-if="!isFetchingJobDetails" />
    <div v-else-if="isJobFound">
      <div class="pb-1 mb-1">
        <CRow class="m-0">
          <CCol md="12" sm="12" lg="6" class="d-block d-lg-none pb-3 pt-3">
            <div class="float-right">
              <CButton
                class="mr-2 nowrap"
                color="primary"
                shape="pill"
                @click="backtocardview"
                >Back</CButton
              >
              <CButton
                id="backbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getPreviousJobId"
                color="primary"
                shape="pill"
                @click="navigateto(getPreviousJobId)"
              >
                <CIcon name="cil-caret-left" />
              </CButton>
              <CButton
                id="nextbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getNextJobId"
                color="primary"
                shape="pill"
                @click="navigateto(getNextJobId)"
              >
                <CIcon name="cil-caret-right" />
              </CButton>
            </div>
          </CCol>
          <CCol md="12" sm="12" lg="6">
            <h3 class="text-primary main-title mt-2">
              Job Detailed View
              <CIcon
                name="cil-info"
                class="hinticon-multi-select"
                v-c-tooltip="{
                  content:
                    'To edit job details please go to the Job Board and select Edit on the required Job',
                  placement: 'right',
                }"
              />
            </h3>
          </CCol>
          <CCol md="12" sm="12" lg="6" class="d-none d-lg-block">
            <div class="float-right">
              <CButton
                class="mr-2 nowrap"
                color="primary"
                shape="pill"
                @click="backtocardview"
                >Back</CButton
              >
              <CButton
                id="prevbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getPreviousJobId"
                color="primary"
                shape="pill"
                @click="navigateto(getPreviousJobId)"
              >
                <CIcon name="cil-caret-left" />
              </CButton>
              <CButton
                id="nextbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getNextJobId"
                color="primary"
                shape="pill"
                @click="navigateto(getNextJobId)"
              >
                <CIcon name="cil-caret-right" />
              </CButton>
            </div>
          </CCol>
        </CRow>
      </div>
      <CRow class="m-0 pb-4 mb-3">
        <CCol lg="12">
          <CCard>
            <CCardBody>
              <CRow class="pb-3">
                <CCol lg="4" class="pb-2 px-0 dark-block">
                  <div class="cand-info-img text-center m-auto pb-1">
                    <img
                      :src="iconUrl"
                      onerror="this.src='/img/hospital.png';"
                      class="rounded-circle ratio-1"
                      alt
                    />
                  </div>
                  <div class="text-center">
                    <h5 class="m-0">
                      {{ organisation }}
                    </h5>
                    <p>{{ organisationLocation }}</p>
                    <div class="job-label reference">
                      <label class="setfont">Job ID : {{ referenceId }}</label
                      >&nbsp;&nbsp;&nbsp;
                      <span v-if="profile.featured" class="text-gold">
                        <em class="fa fa-star"></em>
                      </span>
                      <span v-else class="text-secondary">
                        <em class="fa fa-star"></em>
                      </span>
                    </div>
                    <div class="job-label">
                      <label>Advertised:</label>&nbsp;&nbsp;&nbsp;&nbsp;
                      <span
                        v-if="getJobDetailsById.job_status.status == 'Active'"
                        class="status"
                        style="color: green"
                      >
                        <em class="fas fa-check-circle"></em>
                        {{ publishStatus }}
                      </span>
                      <span
                        v-else-if="
                          getJobDetailsById.job_status.status == 'Inactive'
                        "
                        class="status"
                        style="color: red"
                      >
                        <em class="fas fa-times-circle"></em>
                        {{ publishStatus }}
                      </span>
                      <span
                        v-else-if="
                          getJobDetailsById.job_status.status == 'Pending'
                        "
                        class="status"
                        style="color: orange"
                      >
                        <em class="fas fa-business-time"></em>
                        {{ publishStatus }}
                      </span>
                      <span
                        v-else-if="
                          getJobDetailsById.job_status.status == 'Draft'
                        "
                        class="status"
                        style="color: grey"
                      >
                        <em class="fas fa-archive"></em>
                        {{ publishStatus }}
                      </span>
                      <span
                        v-else-if="
                          getJobDetailsById.job_status.status == 'Archive'
                        "
                        class="status"
                        style="color: blue"
                      >
                        <em class="fas fa-briefcase"></em>
                        {{ publishStatus }}
                      </span>
                    </div>
                    <div class="job-label">
                      <label>Posted Date:</label>&nbsp;&nbsp;
                      <span class="text-primary bold">{{
                        postedDate(getJobDetailsById.created_on)
                      }}</span>
                    </div>
                    <div class="job-label expiry">
                      <label>Expiry:</label>&nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="text-primary bold"
                        >{{ expiryFormatedDate }} / {{ expiryDays }}</span
                      >
                    </div>
                    <div class="job-label">
                      <CButton
                        color="primary"
                        class="px-2 f-12"
                        @click="previewJob"
                      >
                        Preview
                      </CButton>
                    </div>
                  </div>
                  <div>
                    <JobContactInfo :profile="profile" :isCandidate="true" />
                  </div>
                </CCol>
                <CCol lg="8">
                  <CRow class="mb-2">
                    <CCol lg="12">
                      <ConsultantDetails :profile="profile" />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol lg="12">
                      <Preferences :profile="profile" />
                    </CCol>
                    <CCol lg="12">
                      <DohDetails :profile="profile" />
                    </CCol>
                    <CCol lg="12">
                      <AdditionalInfo :profile="profile" />
                    </CCol>
                  </CRow>
                  <!-- <CRow>
                    <CCol lg="12">
                      <Details :profile="profile" />
                    </CCol>
                  </CRow> -->
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <!-- <CCard>
            <CCardBody>
              <GenericTabFilters
                :isShowRefresh="genericTabFilter.isShowRefresh"
                :refreshCallBack="refreshCallBack"
                :filterCallBack="filterCallBack"
                :activeTab="information_step"
                :tabSelectCallBack="tabSelectCallBack"
                :tabs="tabs"
                :isShowFavourite="true"
                page="JobDetails"
                ref="GenericTabFilters"
              />
              <div class="info-content mt-2">
                <div v-if="information_step === 0 && !getTabInfo['tab_id']">
                  <JobRequirementDetails
                    :profile="profile"
                    ref="JobRequirementDetails"
                  />
                </div>
                <div v-if="information_step && getTabInfo['tab_id']">
                  <Candidates
                    :candidatesInfo="candidates"
                    :selectedCandidate="candidateDetails"
                    @getCandidateDetails="getCandidateDetails"
                    :selectedTabIndex="information_step"
                    @actionModalEvent="actionModalEvent"
                    :actionModal="actionModal"
                  />
                  <div
                    v-if="noMoreCandidates && candidates.length"
                    class="text-center mt-2"
                  >
                    <CBadge color="primary" class="font-weight-normal"
                      ><span>No More Candidates Found</span></CBadge
                    >
                  </div>
                </div>
                <div v-if="information_step === 5 && !getTabInfo['tab_id']">
                  <history-info
                    ref="historyInfo"
                    :jobDetails="getJobDetailsById"
                  />
                </div>
              </div>
            </CCardBody>
          </CCard> -->
        </CCol>

        <CCol lg="4" id="candidateDetails" class="paddingself">
          <CCard class="child2-layout p-3" v-if="isCandidateDetailsNotEmpty">
            <CRow v-if="candidateStatus" class="bottom-line mb-4">
              <CCol col="8" class="text-primary font-weight-bold">
                <CRow class="pb-2">
                  <CCol col="12">
                    <div class="d-flex">
                      <span class="mr-2">Status:</span>
                      <span>
                        <span>{{ candidateStatus }} </span>
                        <span class="text-nowrap" v-if="candidateSubStatus">{{
                          `(${candidateSubStatus})`
                        }}</span>
                      </span>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
              <CCol col="4">
                <div
                  class="d-flex justify-content-end"
                  v-if="index_selectedCandidate > -1"
                >
                  <CButton
                    class="pt-0 pb-0"
                    @click="candidateDetails = previousCandidate"
                    :disabled="!previousCandidate"
                  >
                    <CIcon name="cil-caret-left" class="text-primary" />
                  </CButton>
                  <CButton
                    class="pt-0 pb-0"
                    @click="candidateDetails = nextCandidate"
                    :disabled="!nextCandidate"
                  >
                    <CIcon name="cil-caret-right" class="text-primary" />
                  </CButton>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="4" class="px-0 mb-1">
                <div>
                  <div class="d-flex">
                    <img
                      :src="candidateProfilePicture"
                      alt="DP"
                      class="rounded-circle display-img candidateimg m-auto"
                    />
                  </div>
                  <span
                    class="fs-13 pt-2 d-block text-center"
                    style="color: #1571cc; font-weight: 500"
                    >{{ name }}</span
                  >
                  <span class="d-block text-center"
                    >Candidate Id: {{ candidateId }}</span
                  >
                </div>
              </CCol>
              <CCol lg="8" class="mb-1">
                <CRow>
                  <CCol lg="5" class="px-0 mb-1">
                    <h5 class="text-primary text-center">
                      {{ candidateType }}
                    </h5>
                  </CCol>
                  <CCol lg="6" class="mb-1 px-0">
                    <CRow class="mx-0 mb-1">
                      <CCol col="12" class="px-0 d-flex">
                        <div class="d-flex action-set">
                          <CDropdown
                            placement="left"
                            class="text-nowrap text-primary mx-1 drop-padding"
                            v-if="currentUserRole === Role.customerAdmin"
                          >
                            <template #toggler>
                              <div class="action-1 font-weight-bold">
                                <em
                                  class="fas fa-ellipsis-v circle-border"
                                ></em>
                                Action
                              </div>
                            </template>
                            <a
                              class="dropdown-item cursor-action"
                              v-for="data in candidateActions"
                              :key="data.action_id"
                              @click="candidateActionEvent(data)"
                            >
                              {{ data.label }}
                            </a>
                          </CDropdown>
                          <CButton
                            size="sm"
                            color="primary"
                            class="action-1"
                            @click="navigateToCandidateDetail"
                          >
                            View
                          </CButton>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow
                      class="mx-0"
                      v-if="currentUserRole === Role.customerAdmin"
                    >
                      <CCol col="12" class="px-0 mb-1 d-flex">
                        <div class="d-flex action-set">
                          <CDropdown
                            placement="left"
                            class="text-nowrap text-primary mx-1 drop-padding"
                          >
                            <template #toggler>
                              <div class="action-1 font-weight-bold">
                                <em
                                  class="fas fa-ellipsis-v circle-border"
                                ></em>
                                Contact
                              </div>
                            </template>
                            <a
                              class="dropdown-item cursor-action"
                              v-for="data in getContactMethods"
                              :key="data.code"
                              @click="openContactModal(data.code)"
                            >
                              {{ data.label }}
                            </a>
                          </CDropdown>
                          <CButton
                            size="sm"
                            color="primary"
                            variant="outline"
                            class="action-2"
                            @click="openCommentModal"
                          >
                            Comment
                          </CButton>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>

            <div class="scroll-arrow">
              <a
                id="left2"
                class="arrow left clickable fa-disabled"
                @click="arrowClick(2, 'left')"
              >
                <i class="fa fa-chevron-circle-left" aria-hidden="true" />
              </a>
              <a
                id="right2"
                class="arrow right clickable"
                @click="arrowClick(2, 'right')"
              >
                <i class="fa fa-chevron-circle-right" aria-hidden="true" />
              </a>
              <div class="scroll-wrapper">
                <ul class="nav nav-tabs" id="otherinfoNav">
                  <li
                    role="presentation"
                    v-for="(data, index) in candidateDetailsTab"
                    :key="index"
                    v-bind:class="{ active: step2 === index }"
                  >
                    <a
                      class="clickable btn btnpadding"
                      @click="fetchShortListInProgressJobs(index)"
                      >{{ data }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="info-content mt-2">
              <div v-if="step2 === 0">
                <PersonalInfo
                  class="pl-3 personal-info"
                  :candidate="candidateInfo"
                />
              </div>
              <div v-if="step2 === 1">
                <Documents />
              </div>
              <div v-if="step2 === 2">
                <CandidateJobs :jobs="getShortListInProgressJobs" />
              </div>
            </div>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <h4 v-else class="text-muted text-center pt-4 mt-4">No Job Found</h4>
    <JobCardPreviewModal
      :isShowPopup="previewModal"
      :onCancelPreview="onCancelPreview"
    />
    <ActionModal
      v-if="actionModal.isShowPopup"
      :isShowPopup="actionModal.isShowPopup"
      :details="actionModal.candidateDetails"
      :action="actionModal.action"
      @modalCallBack="actionModalCallBack"
    />
    <CommentModal
      v-if="commentModal.isShowPopup"
      :isShowPopup="commentModal.isShowPopup"
      :candidate_id="commentModal.candidate_id"
      :isBtnHide="this.isBtnHide"
      @commentModalCallBack="commentModalCallBack"
    />
    <CToaster
      v-if="getUpdateToast.isShow"
      :autohide="getUpdateToast.autohide"
      position="bottom-center"
    >
      <CToast :class="getUpdateToast.cssClass" :show="true">{{
        getUpdateToast.msg || ""
      }}</CToast>
    </CToaster>
    <EmailModal
      v-if="contactModal.email"
      :isShowPopup="contactModal.email"
      :email_id="this.candidateDetails['candidate'].email"
      :job_id="this.candidateDetails.job_id"
      :candidate_uid="this.candidateDetails.candidate_uid"
      :alternate_email="this.candidateDetails['candidate'].alternate_email"
      @emailModalCallBack="modalCallBack"
      @conversationModalCallBack="conversationModalCallBack"
      @openCommentModal="openCommentModal"
    />
    <MessageModal
      v-if="contactModal.message"
      :isShowPopup="contactModal.message"
      :phone="this.candidateDetails['candidate'].phone"
      :modal="this.modal"
      @messageModalCallBack="modalCallBack"
      @openCommentModal="openCommentModal"
    />
    <MessageModal
      v-if="contactModal.whatsapp"
      :isShowPopup="contactModal.whatsapp"
      :phone="this.candidateDetails['candidate'].whatsapp"
      :modal="this.modal"
      @messageModalCallBack="modalCallBack"
      @openCommentModal="openCommentModal"
    />
    <SubmissionModal
      :modalTitle="submissionModal.modalTitle"
      :modalColor="submissionModal.modalColor"
      :modalContent="submissionModal.modalContent"
      :isShowPopup="submissionModal.isShowPopup"
      :buttons="submissionModal.buttons"
      :modalCallBack="submissionModalCallBack"
      :closeOnBackdrop="submissionModal.closeOnBackdrop"
      v-if="submissionModal.isShowPopup"
    />
    <DeleteModal
      v-if="deleteModal.isShowPopup"
      :isShowPopup="deleteModal.isShowPopup"
      :delete_id="deleteModal.delete_id"
      :email_subject="deleteModal.subject"
      @deleteModalCallBack="deleteModalCallBack"
      @emailModalCallBack="modalCallBack"
    />
    <Modal
      :modalTitle="skypeModal.modalTitle"
      :modalColor="skypeModal.modalColor"
      :modalContent="skypeModal.modalContent"
      :isShowPopup="contactModal.phone"
      :buttons="skypeModal.buttons"
      :modalCallBack="skypeModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  isObject,
  isEmptyObjectCheck,
  getScope,
  Role,
  deepClone,
} from "@/helpers/helper";
import moment from "moment";

import ContactInfo from "@/containers/CandidateDetailsTalentFind/ContactInfo";
import JobContactInfo from "@/containers/JobsDetailedView/ContactInformation";
import JobDetails from "@/containers/CandidateDetailsTalentFind/JobDetails";
import Details from "@/containers/JobsDetailedView/Details";
import DohDetails from "@/containers/JobsDetailedView/DohDetails";
import ConsultantDetails from "@/containers/JobsDetailedView/ConsultantDetails";
import Applications from "@/containers/JobsDetailedView/Application/Applications";
import PersonalInfo from "@/containers/JobsDetailedView/Information/PersonalInfo";
import Candidates from "@/containers/JobsDetailedView/Candidate/CandidateMatch";
import Preferences from "@/containers/JobsDetailedView/Preferences";
import AdditionalInfo from "@/containers/JobsDetailedView/AdditionalInfo";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
import JobRequirementDetails from "@/containers/JobsDetailedView/Application/JobRequirementDetails";
import Comments from "@/containers/JobsDetailedView/Application/Comments";
import Vault from "@/containers/JobsDetailedView/Application/Vault";
import JobMatch from "@/containers/JobsDetailedView/Candidate/JobMatch";
import JobInProcess from "@/containers/JobsDetailedView/Candidate/JobInProcess";
import Documents from "@/containers/JobsDetailedView/Candidate/Documents";
import Select from "@/components/reusable/Fields/Select";
import GenericTabFilters from "@/components/reusable/GenericTabFilters/index";
import ActionModal from "@/components/reusable/GenericTabFilters/ActionModal";
import CommentModal from "@/containers/JobsDetailedView/Candidate/CommentModal";
import HistoryInfo from "@/containers/JobsDetailedView/History/HistoryInfo.vue";
import EmailModal from "@/containers/Communication/EmailModal";
import MessageModal from "@/containers/Communication/MessageModal";
import CandidateJobs from "@/containers/JobsDetailedView/Candidate/CandidateJobs";
import SubmissionModal from "@/components/reusable/Modal";
import DeleteModal from "@/containers/Communication/DeleteModal";
import Modal from "@/components/reusable/Modal";
export default {
  name: "CandidateDetails",
  components: {
    ContactInfo,
    JobDetails,
    Details,
    DohDetails,
    ConsultantDetails,
    Applications,
    PersonalInfo,
    Candidates,
    AdditionalInfo,
    Preferences,
    JobContactInfo,
    JobCardPreviewModal,
    JobRequirementDetails,
    Comments,
    Vault,
    JobMatch,
    JobInProcess,
    Documents,
    Select,
    GenericTabFilters,
    ActionModal,
    CommentModal,
    HistoryInfo,
    EmailModal,
    MessageModal,
    CandidateJobs,
    SubmissionModal,
    DeleteModal,
    Modal,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      information_step: 0,
      candidateDetailsTab: ["Information", "Documents", "Jobs"],
      step1: 0,
      step2: 0,
      candidateDetails: null,
      previewModal: false,
      MatchedjobDetails: null,
      secondaryFilters: {},
      genericTabFilter: { isShowRefresh: false },
      actionModal: {
        isShowPopup: false,
        candidate_uid: null,
        candidateDetails: null,
        action: null,
      },
      commentModal: {
        isShowPopup: false,
        candidate_uid: null,
      },
      contactModal: {
        email: false,
        phone: false,
        whatsapp: false,
        message: false,
        mail: false,
        comment: false,
      },
      modal: null,
      submissionModal: {
        modalTitle: "Submission",
        modalColor: "primary",
        modalContent: "Is the profile ready for submission?",
        isShowPopup: false,
        buttons: ["No", "Yes"],
        closeOnBackdrop: false,
      },
      deleteModal: {
        isShowPopup: false,
        delete_id: null,
        subject: null,
      },
      messageId: null,
      isBtnHide: true,
      methods: null,
      skypeModal: {
        modalColor: "danger",
        modalTitle: "Skype",
        modalContent:
          "Please ensure you have Skype installed, to proceed with phone call!",
        buttons: ["Cancel", "Ok"],
      },
    };
  },
  computed: {
    ...mapGetters([
      "isFetchingJobDetails",
      "jobListBackUrl",
      "getPreviousJobId",
      "getNextJobId",
      "getJobs",
      "getJobDetailsById",
      "getCandidateListForJobID",
      "noMoreCandidates",
      "getSubStatusOptions",
      "getJobCandidateTabs",
      "getJobFilterOptions",
      "getJobSubFilterOptions",
      "getTabInfo",
      "getDisplayStatusSubStatus",
      "getTabFilterCandidateCount",
      "getCandidateDetailsForID",
      "getUpdateToast",
      "getContactMethods",
      "getShortListInProgressJobs",
    ]),
    options() {
      return {
        filterMatches: [
          { code: null, label: "All" },
          { code: true, label: "Applied" },
          { code: false, label: "Talent Pool" },
        ],
        filterShortlists: this.getSubStatusOptions || [],
        mainFilter: this.getJobFilterOptions || [],
        subFilter: this.getJobSubFilterOptions || [],
      };
    },
    Matchedjobs() {
      if (this.getJobs && this.getJobs.length) {
        if (this.MatchedjobDetails === null)
          this.MatchedjobDetails = this.getJobs[0];
        return this.getJobs;
      }
      return [];
    },
    // candidates() {
    //   if (
    //     this.getCandidateListForJobID &&
    //     this.getCandidateListForJobID.length
    //   ) {
    //     if (this.candidateDetails === null) {
    //       this.candidateDetails = this.getCandidateListForJobID[0];
    //     }
    //     return this.getCandidateListForJobID;
    //   }
    //   this.candidateDetails = null;
    //   return [];
    // },
    isCandidateRole() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
    isCustomerUserRole() {
      return this.currentUserRole === Role.customerUser;
    },
    organisation() {
      return this.getJobDetailsById?.organisation?.name || null;
    },
    organisationLocation() {
      return this.getJobDetailsById.location?.location || "--";
    },
    referenceId() {
      return `${this.getJobDetailsById?.job_id}` || "--";
    },
    expiryFormatedDate() {
      return this.getJobDetailsById?.expiry_review_date
        ? moment(this.getJobDetailsById?.expiry_review_date).format(
            "DD-MM-YYYY"
          )
        : "--";
    },
    candidateInfo() {
      return this.candidateDetails?.candidate;
    },
    name() {
      return this.candidateInfo?.full_name || null;
    },
    candidateId() {
      return this.candidateInfo?.candidate_uid || null;
    },
    candidateType() {
      return this.candidateInfo?.candidate_type?.candidate_type || null;
    },
    age() {
      return this.candidateInfo?.age != null
        ? `${this.candidateInfo?.age} Years`
        : null;
    },
    role() {
      return this.candidateInfo?.role || null;
    },
    gender() {
      return this.candidateInfo?.gender?.gender != null
        ? `${this.candidateInfo?.gender?.gender}, `
        : null;
    },
    nationality() {
      return this.candidateInfo?.nationality?.nationality != null
        ? `${this.candidateInfo?.nationality?.nationality}, `
        : null;
    },
    publishStatus() {
      return this.getJobDetailsById.job_status.status == "Active"
        ? "Published"
        : this.getJobDetailsById.job_status.status;
    },
    expiryDays() {
      let days = this.getJobDetailsById.expiry_review_date
        ? this.expiryDate(this.getJobDetailsById.expiry_review_date) < 0
          ? "Already Expired"
          : this.expiryDate(this.getJobDetailsById.expiry_review_date) +
            "\xa0" +
            "Days"
        : 0;
      return days;
    },
    iconUrl() {
      return this.getJobDetailsById?.organisation?.logo_url
        ? `https://${window.location.host}${this.getJobDetailsById?.organisation?.logo_url}`
        : "/img/hospital.png";
    },
    profile() {
      return {
        ...this.getJobDetailsById,
      };
    },
    isCandidateDetailsNotEmpty() {
      return (
        isObject(this.candidateDetails) &&
        !isEmptyObjectCheck(this.candidateDetails)
      );
    },
    candidateTabs() {
      let tabs = this.getJobCandidateTabs;
      tabs = [
        { tab_id: null, tab_name: "Details" },
        ...tabs,
        { tab_id: null, tab_name: "Comments" },
      ];
      return tabs;
    },
    tab_id() {
      return this.getTabInfo?.tab_id || null;
    },
    candidateStatus() {
      return this.candidateDetails?.status?.display_text || null;
    },
    candidateSubStatus() {
      return this.candidateDetails?.sub_status?.display_text || null;
    },
    index_selectedCandidate() {
      let selectedCandidate_id =
        this.candidateDetails?.candidate?.candidate_uid;
      return this.getCandidateListForJobID.findIndex(
        ({ candidate }) => candidate?.candidate_uid === selectedCandidate_id
      );
    },
    previousCandidate() {
      if (this.candidateDetails && this.index_selectedCandidate) {
        if (
          this.getCandidateListForJobID &&
          this.getCandidateListForJobID.length
        ) {
          if (this.index_selectedCandidate > 0)
            return this.getCandidateListForJobID[
              this.index_selectedCandidate - 1
            ];
        }
      }
      return null;
    },
    nextCandidate() {
      if (this.candidateDetails) {
        let customer_uid = this.candidateDetails?.candidate?.customer_uid;
        let candidate_uid = this.candidateDetails?.candidate?.candidate_uid;
        this.fetchspecialInterest({
          customer_uid: customer_uid,
          candidate_uid: candidate_uid,
        });
        this.fetchCandidateNonMedicalQuals({
          customer_uid: customer_uid,
          candidate_uid: candidate_uid,
        });
      }
      if (this.candidateDetails && this.index_selectedCandidate > -1) {
        if (
          this.getCandidateListForJobID &&
          this.getCandidateListForJobID.length
        ) {
          if (
            this.index_selectedCandidate > -1 &&
            this.index_selectedCandidate <
              this.getCandidateListForJobID.length - 1
          )
            return this.getCandidateListForJobID[
              this.index_selectedCandidate + 1
            ];
        }
      }
      return null;
    },
    candidateActions() {
      return (
        (this.candidateDetails &&
          this.candidateDetails.actions &&
          this.candidateDetails.actions.map((val) => {
            return {
              label: val?.name,
              action_id: val?.id,
              directAction: val?.direct_action,
              status: val?.status,
              filter_id: val?.filter,
              tab_id: val?.tab,
              reason_id: val?.reason,
            };
          })) ||
        []
      );
    },
    candidateProfilePicture() {
      return this.candidateInfo?.profile_picture || `/img/user_default_pic.png`;
    },
    tabs() {
      let tabs = [{ tab_id: null, tab_name: "Details" }];
      // if (!this.isCandidateRole && !this.isCustomerUserRole) {
      //   let dynamicTabs = this.getJobCandidateTabs;
      //   tabs = [...tabs, ...dynamicTabs, { tab_id: null, tab_name: "History" }];
      // }
      return tabs;
    },
    isJobFound() {
      return (
        this.isFetchingJobDetails &&
        isObject(this.getJobDetailsById) &&
        !isEmptyObjectCheck(this.getJobDetailsById)
      );
    },
  },
  mounted() {
    let appendAction = [];
    if (
      this.isCandidateRole &&
      isObject(this.getJobDetailsById) &&
      !isEmptyObjectCheck(this.getJobDetailsById)
    ) {
      appendAction = [
        ...appendAction,
        this.commitJobInfo(this.getJobDetailsById),
      ];
    } else {
      appendAction = [
        ...appendAction,
        this.setSelectedjobById(this.$route.params.uuid),
      ];
    }
    // if (!this.isCandidateRole && !this.isCustomerUserRole) {
    //   appendAction = [...appendAction, this.fetchJobCandidateTabs()];
    //   this.information_step = 1;
    // } else {
    //   this.information_step = 0;
    // }
    // Promise.all([appendAction]).then(async (res) => {
    //   await this.initJobDetailsFetch();
    //   this.$nextTick(() => {
    //     const ele = this.$refs.GenericTabFilters;
    //     if (ele) {
    //       ele.initEvent();
    //     }
    //   });
    if (this.isJobFound) this.initJobDetailsFetch();
    this.candidateDetails = null;
    // });
    this.scrollTop();

    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "setSelectedjobById",
      "fetchMoreJobList",
      "fetchJobs",
      "commitCandidateInfo",
      "downloadDocument",
      "initJobDetailsFetch",
      "getCandidateDetails",
      "triggerJobCandidateMatch",
      "fetchCandidateList",
      "commitJobInfo",
      "selectedJobBoard",
      "fetchCandidateListForJobID",
      "fetchspecialInterest",
      "commitCandidateInfo",
      // "fetchLicense",
      "fetchCandidateLanguage",
      "fetchUploadedDocument",
      "fetchCandidateListForJobIDNew",
      "initFetchCandidates",
      "initUpdateCandidateStatus",
      "addJobCandidateComment",
      "updateJobCandidateStatus",
      "fetchJobCandidateTabs",
      "setCandidateListBackUrl",
      "initShortListInprogessJobs",
      "fetchCurrency",
      "fetchJobCommonComments",
      "fetchCandidateNonMedicalQuals",
      "fetchspecialInterest",
    ]),
    backtocardview() {
      this.$router.push(this.jobListBackUrl).then((res) => {
        this.$store.commit("REMOVE_JOB_LIST_BACK_URL", this.jobListBackUrl);
      });
    },
    navigateto(uuid) {
      this.fetchJobCommonComments(uuid);
      this.$router.push({ path: `/job-list-preview/${uuid}` });
    },
    navigateToCandidateDetail() {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.$router.push(`/candidate/${this.candidateId}`);
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    fileDownload(data) {
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name });
      return false;
    },
    // getCandidateDetails(candidateDetails) {
    //   if (candidateDetails) {
    //     const { candidate, sub_status } = candidateDetails;
    //     this.candidateDetails = candidateDetails;
    //     let appendAction;
    //     appendAction = this.commitCandidateInfo(candidate);
    //     Promise.all([appendAction]).then((res) => {
    //       if (this.step2 === 2) this.initShortListInprogessJobs();
    //       this.fetchspecialInterest();
    //       this.fetchLicense();
    //       this.fetchCandidateLanguage();
    //       this.fetchUploadedDocument();
    //       this.fetchCurrency();
    //     });
    //   }
    //   setTimeout(() => {
    //     document.getElementById("candidateDetails").scrollIntoView();
    //   }, 100);
    // },
    candidateState(event) {
      let name = event.currentTarget.name;
      let cname = {
        delete: "Delete",
        deactivate: "Deactivate",
      };
      this.popupModal.modalReasonType = name.toUpperCase();
      this.popupModal.modalTitle = `${name} Candidate`;
      this.popupModal.modalContent = `Are you sure to ${name.toLowerCase()} the candidate? If Yes please select an appropriate reason..`;
      this.popupModal.isShowPopup = true;
      if (name === cname.delete || name === cname.deactivate) {
        this.popupModal.modalColor = "danger";
      } else {
        this.popupModal.modalColor = "primary";
      }
    },

    arrowClick(direction) {
      let e = document.querySelector("#otherinfoNav");
      if (e != null)
        if (direction === "left") {
          e.scrollLeft -= 150;
        } else if (direction === "right") {
          e.scrollLeft += 150;
        }
    },
    goToPreferenceSection() {
      this.$refs.GenericTabFilters.selectTab(0, null);
      this.tabSelectCallBack({ index: 0 });
      setTimeout(() => {
        this.$refs.JobRequirementDetails.isExpand.qualification = true;
        let el = this.$el.getElementsByClassName(
          "jobQualification-info-section"
        )[0];
        // el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -70);
      }, 50);
    },
    goToSalaryAndBenefitsSection() {
      this.$refs.GenericTabFilters.selectTab(0, null);
      this.tabSelectCallBack({ index: 0 });
      setTimeout(() => {
        this.$refs.JobRequirementDetails.isExpand.salary = true;
        let el = this.$el.getElementsByClassName("jobBenefits-info-section")[0];
        // el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -70);
      }, 50);
    },
    onCancelPreview() {
      this.previewModal = false;
    },
    previewJob() {
      const id = this.getJobDetailsById.job_uuid;
      this.selectedJobBoard(id);
      this.previewModal = true;
    },
    expiryDate(expiryDate) {
      let expiration = moment(expiryDate);
      let current_date = moment();
      return expiration.diff(current_date, "days");
    },
    postedDate(postdate) {
      return moment(postdate).format("DD-MM-YYYY");
    },
    onBodyScroll(e) {
      if (this.noMoreCandidates) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
          e.target.scrollHeight - 1 &&
        this.getDisplayStatusSubStatus.length &&
        this.tab_id
      ) {
        this.fetchCandidateListForJobIDNew({ pagination: true });
      }
    },
    fetchCandidateNew(data) {
      let payload = { pagination: data || false };
      this.initFetchCandidates(payload);
    },
    refreshCallBack() {
      let appendAction = this.triggerJobCandidateMatch({
        job_id: this.getJobDetailsById?.job_id,
        job_uuid: this.getJobDetailsById?.job_uuid,
      });
      Promise.all([appendAction]).then((res) => {
        this.fetchCandidateListForJobIDNew({ pagination: false });
      });
    },
    filterCallBack(isFavourite) {
      this.fetchCandidateListForJobIDNew({ pagination: false, isFavourite });
    },
    tabSelectCallBack({ index, tab_id }) {
      this.information_step = index;
    },
    actionModalCallBack(action, payload) {
      if (!action) this.actionModal.isShowPopup = false;
      if (action && payload) {
        const { job_id } = this.getJobDetailsById;
        const candidate_uid = this.actionModal.candidate_uid;
        const appentAction = this.initUpdateCandidateStatus({
          candidate_uid,
          job_id,
          payload,
        });
        Promise.all([appentAction]).then((res) => {
          if (res) {
            this.actionModal.isShowPopup = false;
            this.actionModal.candidate_uid = null;
            this.candidateDetails = null;
          }
        });
      }
    },
    openCommentModal(messageId, columnKey) {
      this.commentModal.isShowPopup = true;
      this.commentModal.candidate_id = this.candidateDetails?.candidate_uid;
      this.contactModal[columnKey] = false;
      if (columnKey) {
        this.messageId = messageId;
        this.isBtnHide = false;
      } else {
        this.messageId = null;
      }
    },
    commentModalCallBack(action, payload) {
      if (action) {
        const { job_id } = this.getJobDetailsById;
        payload = {
          ...payload,
          candidate_id: this.commentModal.candidate_id,
          job_id,
          contact_method: this.methods,
          message_id: this.messageId,
        };
        this.addJobCandidateComment(payload).then((res) => {
          this.commentModal.isShowPopup = false;
          this.commentModal.candidate_id = null;
          if (this.information_step === 5)
            this.$refs.historyInfo.fetchJobAuditHistory(false);
        });
      } else {
        this.commentModal.isShowPopup = false;
      }
    },
    // actionModalEvent(payload) {
    //   const { candidateDetails, selectedAction } = payload;
    //   const { action_id, directAction, status, reason_id } = selectedAction;
    //   const { candidate_uid } = candidateDetails;
    //   const { job_id } = this.getJobDetailsById;
    //   if (directAction) {
    //     let uploadPayload = {};
    //     uploadPayload = {
    //       ...uploadPayload,
    //       payloadData: { job_id, candidate_uid, payload: { reason_id } },
    //     };
    //     uploadPayload = { ...uploadPayload, updateStatusSubStatus: status };
    //     this.updateJobCandidateStatus(uploadPayload).then((res) => {
    //       this.hideDropDown();
    //       this.candidateDetails = null;
    //     });
    //   } else {
    //     this.actionModal["candidate_uid"] = candidate_uid;
    //     this.actionModal["candidateDetails"] = candidateDetails;
    //     this.actionModal["action"] = selectedAction;
    //     if (action_id === 14) {
    //       this.submissionModal["isShowPopup"] = true;
    //     } else {
    //       this.actionModal["isShowPopup"] = true;
    //     }
    //   }
    // },
    candidateActionEvent(action) {
      const candidateDetails = this.candidateDetails;
      let payload = { candidateDetails, selectedAction: action };
      // this.actionModalEvent(payload);
    },
    hideDropDown() {
      const cols = document.getElementsByClassName("dropdown-menu");
      cols.forEach((element) => {
        element.classList.remove("show");
      });
    },
    openContactModal(columnKey) {
      this.modal = columnKey;
      this.methods = columnKey;
      this.contactModal[columnKey] = true;
    },
    modalCallBack(columnKey) {
      this.contactModal[columnKey] = false;
    },
    fetchShortListInProgressJobs(index) {
      this.step2 = index;
      if (this.step2 === 2) {
        this.initShortListInprogessJobs();
      }
    },
    skypeModalCallBack(action) {
      let confirm = "Ok";
      if (action === confirm) {
        this.skypeAction(this.candidateDetails["candidate"].phone);
      }
      this.contactModal.phone = false;
    },
    skypeAction(phone) {
      let url = "skype:" + phone + "?call";
      const link = document.createElement("a");
      link.setAttribute("href", url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    submissionModalCallBack(action) {
      this.submissionModal["isShowPopup"] = false;
      if (action === "Yes") {
        this.actionModal["isShowPopup"] = true;
      } else {
        this.actionModal["isShowPopup"] = false;
      }
    },
    conversationModalCallBack(deleteId, subject) {
      this.deleteModal.isShowPopup = true;
      this.deleteModal.delete_id = deleteId;
      this.deleteModal.subject = subject;
    },
    deleteModalCallBack() {
      this.deleteModal.isShowPopup = false;
      this.contactModal.email = true;
    },
  },
  watch: {
    nextCandidate(val) {
      if (!this.noMoreCandidates && val === null) {
        this.fetchCandidateListForJobIDNew({ pagination: true });
      }
      return;
    },
    "$route.params.uuid": function (id) {
      let appendAction = this.setSelectedjobById(this.$route.params.uuid);
      Promise.all([appendAction]).then(async (res) => {
        await this.initJobDetailsFetch();
        if (!this.getNextJobId) this.fetchMoreJobList();
        this.candidateDetails = null;
        await this.fetchCandidateListForJobIDNew({ pagination: false });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sub-menu {
  width: 12rem;
  padding: 1rem 0.5rem;
}
.job-label {
  // margin-left: 1.5rem;
  // margin-top: 0.25rem;
  span.bold {
    font-weight: 600;
  }
  .reference {
    label.setfont {
      font-weight: normal !important;
    }
  }
}

.job-label .expiry {
  margin-left: 0.5rem !important;
}
label {
  font-weight: bold;
  opacity: 0.9;
  font-size: 0.8rem;
}
span {
  font-size: 0.8rem;
}
i {
  font-size: 0.9rem;
}
.candidateimg {
  height: 7rem;
  width: 7rem;
}
.w-33 {
  width: 33% !important;
}
.paddingself {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.btnpadding {
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important;
}
.text-gold {
  color: #ff7600;
}
.w-85 {
  width: 85px;
}
.w-70 {
  width: 70px;
}
.w-67 {
  width: 67%;
}
.bottom-line {
  margin-bottom: 6px;
  border-bottom: dotted #eee 2px;
  padding-top: 8px;
}
.candidate-arrow {
  cursor: pointer;
  i {
    font-size: 20px;
  }
}
.action-1,
.action-2 {
  width: 75px;
  height: 24px;
  font-size: 11px;
  margin: auto;
}
.action-set {
  margin-right: 0;
  margin-left: auto;
  @media all and (max-width: 480px) {
    float: none;
    margin: auto;
  }
}
.drop-padding {
  padding: 0.25rem 0;
  cursor: pointer;
  width: 75px;
}
.circle-border {
  border: 1px solid #dd3651;
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
}
</style>
