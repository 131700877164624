<template>
  <div class="pb-2">
    <div v-if="jobs.length || isFetchingCandidateJobInProcessList">
      <CRow id="job-inprogress">
        <CCol
          lg="6"
          v-for="{ job, sub_status, job_applied, status } in jobs"
          :key="job.job_id"
        >
          <CCard class="job-card">
            <CCardBody class="p-1 pt-2 position-relative">
              <div class="content-job-title p-2">
                <div class="job-content">
                  <div class="image-wrapper">
                    <img :src="getOrganisationLogo(job)" />
                  </div>
                  <h6 class="d-block">{{ job.job_title }}</h6>
                  <span class="d-block f-13">
                    <span class="text-muted">ID:</span>
                    <span v-if="job.job_id === getJobDetailsById.job_id">{{
                      job.job_id
                    }}</span>
                    <a
                      v-else
                      href="javascript:"
                      @click="getToJobDetails(job.job_uuid)"
                      >{{ job.job_id }}</a
                    >
                  </span>
                  <span class="d-block"></span>
                  <span
                    class="d-block text-muted f-13"
                    v-if="job.organisation.name"
                    >{{ job.organisation.name }}</span
                  >
                  <span class="text-muted">
                    <span class="icon-location"></span>
                    <span class="f-13">{{ getHospitalLocation(job) }}</span>
                  </span>
                </div>
                <div class="text-left mb-3 ml-2" style="font-size: medium">
                  <CBadge
                    color="success"
                    class="font-weight-normal ml-1 f-11"
                    v-if="job_applied"
                  >
                    <span>Applied</span></CBadge
                  >
                  <CBadge
                    color="primary"
                    class="font-weight-normal ml-1 f-11"
                    v-else
                    ><span>Talent Pool</span></CBadge
                  >
                  <CBadge
                    color="info"
                    class="font-weight-normal ml-1 text-white f-11"
                    v-if="status && status['display_text']"
                    ><span>{{ status["display_text"] }}</span></CBadge
                  >
                  <CBadge
                    color="warning"
                    class="font-weight-normal ml-1 text-white f-11"
                    v-if="sub_status && sub_status['display_text']"
                    ><span>{{ sub_status["display_text"] }}</span></CBadge
                  >
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol v-if="isFetchingCandidateJobInProcessList" lg="6">
          <div class="skeleton-card"></div>
        </CCol>
      </CRow>
      <div class="text-center mt-2" v-if="noMoreShortListInProgressJobs">
        <CBadge color="primary" class="font-weight-normal ml-1 f-11"
          ><span>No More Jobs Found</span></CBadge
        >
      </div>
    </div>
    <p v-else class="text-center text-gray h5">No Jobs Found</p>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters([
      "getShortListInProgressJobs",
      "noMoreShortListInProgressJobs",
      "isFetchingCandidateJobInProcessList",
      "getJobDetailsById",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchShortListInprogessJobs",
      "initShortListInprogessJobs",
    ]),
    getToJobDetails(job_uuid) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push(`/job-list-preview/${job_uuid}`);
    },
    getOrganisationType(data) {
      return data.organisation?.organisation_type?.organisation_type || "--";
    },
    getOrganisationLogo(data) {
      return data.organisation?.logo_url ? `https://${window.location.host}${data.organisation?.logo_url}` : "/img/hospital.png";
    },
    isSelectedJob(jobID) {
      if (jobID && this.selectedJob) return jobID === this.selectedJob.job_id;
      return false;
    },
    getHospitalLocation(data) {
      return data?.organisation?.location?.location || "--";
    },
    onBodyScroll(e) {
      if (this.noMoreShortListInProgressJobs) return;
      const ele = document.getElementById("job-inprogress");
      if (e.target.scrollTop >= ele.scrollHeight - 1) {
        this.fetchShortListInprogessJobs({ pagination: true });
      }
    },
  },
  mounted() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>
<style lang="scss" scoped>
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 50px;
  }
}
.candidate-card:hover {
  background-color: #fdf1f64d;
}
.candidate-card > .card-body {
  padding-bottom: 30px;
}
.candidate-card.candidate-active {
  background-color: #fdf1f6;
  box-shadow: 0 4px 8px 0 rgba(253, 241, 246, 0.15),
    0 6px 20px 0 rgba(253, 241, 246, 0.14);
}
.action-icon {
  position: absolute;
  top: 1.24em;
  right: 1.4em;
  z-index: 1;
  cursor: pointer;
}

.candidate-status {
  position: absolute;
  bottom: 1.24em;
  // left: 1.4em;
  z-index: 1;
}

.card {
  height: auto;
  min-height: 100%;
}

@media (min-width: 992px) {
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    // border: 1px solid #eee;
    max-width: 30.33333%;
    margin: 0.5rem;
  }
}
.f-13 {
  font-size: 13px;
}
.f-11 {
  font-size: 11px;
}
.job-card {
  border-radius: 11px;
  border: 1px solid #dddddd !important;
  -webkit-box-shadow: -1px -1px 3px 0px #c5c3c3;
  box-shadow: -1px -1px 3px 0px #c5c3c3;
}
</style>
